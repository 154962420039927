import "jquery";
import { Utils } from "./classes/Utils";
import { EditableField } from "./classes/widgets/EditableField";
import { DocsIssuesSelect } from "./classes/helpers/DocsIssuesSelect";

let $pageContent,
    $contentHeader;

$(document).ready(function () {
    $pageContent = $("#pageContent");
    $contentHeader = $("#contentHeader");
});

export let legacy = {

    api_folder: "/api_deprecated/",

    scheduleDays: {
        "ponC": "Понедельник",
        "vtoC": "Вторник",
        "sreC": "Среда",
        "cheC": "Четверг",
        "pyaC": "Пятница",
        "subC": "Суббота",
        "vosC": "Воскресенье",
    },

    globalCallback: function () {
        eval(globObj.callback);
    },

    showError: function (data) {
        console.log("responseText: " + data.responseText);
        $contentHeader.show().text("Найдены ошибки");
        $pageContent.html(data.responseText);
    },

    getStudentList: function (filter) {
        filter = (filter === undefined) ? "" : filter;
        $.ajax({
            url: legacy.api_folder + "studentsGetList.php",
            dataType: "json",
            data: filter,
            type: "POST",
            success: function (data) {
                legacy.renderStudentList(data);
            },
            error: function (data) {
                legacy.showError(data);
            },
        });
    },

    renderStudentList: function (data) {

        let filterValue = "", num = 1, out = "";
        if (data.filter !== undefined) {
            filterValue = data.filter;
        }
        if (data.outerSearch !== undefined) {
            out += `<div class="text-muted">${data.outerSearch.name} Значение: <b>${data.outerSearch.value}</b></div><br>`;
        }
        out += "<div id=\"student-filter\" class=\"input-group mb-3\">";
        out += `<input class="form-control" type="text" placeholder="Поиск по фамилии студента..." aria-label="Поиск по фамилии студента..."  id="searchFIO" value="${filterValue}" aria-describedby="clear-filter">`;
        out += "<button class=\"btn btn-outline-secondary\" type=\"button\" id=\"clear-filter\">X</button>";
        out += "</div>";
        out += "<ol>";
        for (let a in data.list) {
            if (!data.list.hasOwnProperty(a)) continue; // пропустить "не свои" свойства
            let curStudent = data.list[a];
            out += "<li>";
            out += `<a href="javascript:void(0);" class="studentList" data-sID="${curStudent.sID}">${curStudent.student}</a>`;
            out += `<span class='ms-3 text-muted'>Контакт: ${curStudent.contact} - ${curStudent.phone} </span>`;
            out += "</li>";
            num++;
        }
        out += "</ol>";

        $pageContent.html(out);
        $contentHeader.show().text("Список слушателей");

        $(".studentList").each(function (index, element) {
            $(element).on("click", function () {
                legacy.getStudentDetail($(element).attr("data-SID"));
            });
        });

        $("#clear-filter").on("click", legacy.getStudentList);

        $("#searchFIO").on("keypress", function (e) {
            if (e.which === 13) {
                legacy.getStudentList({ "fio": $("#searchFIO").val() });
            }
        });
    },

    /**
     * обработчик для изменения полей слушателя
     * @param event
     */
    editStudentContactField: function (event) {
        let $input = $(event.target),
            name = $input.attr("ID"),
            $editLink = $input.next();
        switch (event.which) {
            case 13:
                $.ajax({
                    url: legacy.api_folder + "studentsFieldEdit.php",
                    dataType: "json",
                    type: "POST",
                    data: { field: name, value: $input.val(), sID: globObj["sID"] },
                    success: function () {
                        legacy.getStudentDetail(globObj["sID"]);
                    },
                });
                break;
            case 27:
                $input.remove();
                $editLink.show();
                break;
        }
        $(document).off("click.removeFieldEditInput");
    },

    renderStudentContacts: function (contactsAr) {
        let $out = $("<div></div>");
        if (typeof contactsAr === "string") {
            let $addLink = $("<a href=\"javascript:void(0);\" title=\"заполнить контактные данные\">заполнить</a>");
            $("#contacts")
                .addClass("noInfo")
                .append("контактная информация отсутствует")
                .append($addLink);
            $addLink.on("click", legacy.studentFillContactsForm);
        } else {
            $.each(contactsAr, (key, value) => {
                let fio = value.f + " " + value.i + " " + value.o,
                    $thisDiv = $("<div class='mb-2'></div>"),
                    $editLink = $("<a class=\"ms-5\" href=\"javascript:void(0);\">изменить</a>");
                $editLink.on("click", legacy.studentEditContactsForm.bind(legacy, value["ID"]));
                $thisDiv.append($("<b></b>").append(fio).append($editLink));
                if (value["phone"] !== "") {
                    $thisDiv.append("<div class=\"phone\">" + value["phone"] + "</div>");
                }

                if (value["email"] !== "") {
                    $thisDiv.append("<div class=\"email\">" + value["email"] + "</div>");
                }

                if (value["document"] !== "") {
                    $thisDiv.append("<div class=\"passport\">есть паспортные данные</div>");
                } else {
                    $thisDiv.append("<div class=\"passport\">паспортные данные не указаны</div>");
                }
                $out.append($thisDiv);
            });
            return $out;
        }
    },

    studentEditContactsForm: function (cID) {

        globObj["callback"] = "legacy.hidePopUp()";
        globObj["cID"] = cID;

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);

            $("#popUpHeader").show().text("Редактирование контактной информации");

            $.ajax({
                url: "/templates/studentAddContacts.html",
                dataType: "html",
                data: {},
                type: "GET",
                success: function (data) {
                    $("#popUpContent").html(data);

                    $.ajax({
                        url: legacy.api_folder + "studentGetContact.php",
                        dataType: "json",
                        data: { cID: cID },
                        type: "POST",
                        success: function (data) {
                            $("#cID").val(data.ID);
                            $("#studentCF").val(data.f);
                            $("#studentCI").val(data.i);
                            $("#studentCO").val(data.o);
                            $("#studentCPh").val(data.phone);
                            $("#studentCE").val(data.email);
                            $("#studentCD").val(data.document.type);
                            $("#studentCPS").val(data.document.serial);
                            $("#studentCPN").val(data.document.number);
                            // сформируем список органов выдающих документы
                            $("#issuedBy").html(
                                DocsIssuesSelect.getDocsIssueSelect(data.document.issued),
                            );
                            // проверим орган выдачи, есть ли в списке такой
                            $("#studentCDD").val(data.document.date);
                            $("#studentCAd").val(data.addr);
                            $("#studentOT").prop('checked', data.otv === "Y");
                        },
                    });
                },
            });
        });
    },

    validateFields: function (arFieldsIds) {
        let noError = true;
        $.each(arFieldsIds, (index, value) => {
            let $field = $("#" + value);
            if ($field.val().length === 0) {
                $field.css({ "border": "1px solid #F00" });
                noError = false;
            }
        });
        return noError;
    },

    addStudentQuery: function () {
        let arValidateFieldsIds = ["studentF", "studentI", "studentO"];
        if (legacy.validateFields(arValidateFieldsIds)) {
            let sData = {};
            $(".editTable input[type=text]").each(function (index, element) {
                sData[$(element).attr("id")] = $(element).val();
            });
            $.ajax({
                url: legacy.api_folder + "studentsAddRecord.php",
                dataType: "json",
                type: "POST",
                data: sData,
                success: function (data) {
                    if (data.ID !== "") {
                        legacy.getStudentDetail(data.ID);
                    }
                },
            });
        }
    },

    editStudentQuery: function () {
        let arValidateFieldsIds = ["studentF", "studentI", "studentO"];

        if (legacy.validateFields(arValidateFieldsIds)) {

            let sData = {};
            $(".editTable input[type=text]").each(function (index, element) {
                sData[$(element).attr("id")] = $(element).val();
            });
            sData.sID = globObj.sID;
            $.ajax({
                url: legacy.api_folder + "studentsSaveEdit.php",
                dataType: "json",
                type: "POST",
                data: sData,
                success: function () {
                    location.reload();
                },
            });
        }
    },

    studentFillContactsForm: function (sID) {
        if (sID) {
            globObj.sID = sID;
        }
        globObj["callback"] = "legacy.hidePopUp()";

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
            $("#popUpHeader").show().text("Добавление контактной информации");

            $.ajax({
                url: "/templates/studentAddContacts.html",
                dataType: "html",
                data: {},
                type: "GET",
                success: function (data) {
                    $("#popUpContent").html(data);

                    // сформируем список органов выдающих документы
                    $("#issuedBy").html(
                        DocsIssuesSelect.getDocsIssueSelect(),
                    );
                    $("#sID").val(sID);

                    $("#studentCF").on("focusout", function () {
                        let searchVal = $("#studentCF").val();
                        if (searchVal.length <= 2) {
                            return false;
                        }
                        $.ajax({
                            url: legacy.api_folder + "studentCheckContacts.php",
                            dataType: "json",
                            data: { f: searchVal },
                            type: "POST",
                            success: function (data) {
                                globObj["fillContacts"] = data["contacts"];
                                if (data["contacts"].length > 0) {
                                    $("#rollOver").fadeIn(500);
                                    $("#rollUp").fadeIn(500);
                                    $("#rollUpHeader").html("Обнаружены контакты!");
                                    let out = "<table>";
                                    for (let a in data.contacts) {
                                        if (!data.contacts.hasOwnProperty(a)) continue;
                                        let current = data.contacts[a],
                                            doc = current["document"];
                                        while (doc.indexOf("|") > 0) {
                                            doc = doc.replace("|", " ");
                                        }
                                        out += "<tr><td><b>" + Utils.concatFio(current.f, current.i, current.o) + "</b><br><small>" + doc + "<br>" + current.addr + "</small></td><td><a href=\"#\" onclick=\"useContactInfo(" + a + ");\" class=\"fillLink\">использовать</a></tr>";
                                    }
                                    out += "<tr><td colspan=2 style=\"text-align: center;\"><input type=\"button\" class=\"btn btn-danger\" onClick=\"return false;hideContactInfo();\" value=\"отменить\"></td></tr>";
                                    out += "</table>";
                                    $("#rollUpContent").html(out);
                                }
                            },
                        });
                    });
                },
            });
        });
    },

    hideContactInfo: function () {
        $("#rollOver").fadeOut(500);
        $("#rollUp").fadeOut(500);
    },

    useContactInfo: function (a) {
        $("#studentCI").val(globObj["fillContacts"][a]["i"]);
        $("#studentCO").val(globObj["fillContacts"][a]["o"]);
        $("#studentCPh").val(globObj["fillContacts"][a]["phone"]);
        $("#studentCE").val(globObj["fillContacts"][a]["email"]);
        if (globObj["fillContacts"][a]["document"] !== "") {
            let doc = globObj["fillContacts"][a]["document"].split("|");
            let $studentCIs = $("#studentCIs");
            $("#studentCD").val(doc[0]);
            $("#studentCPS").val(doc[1]);
            $("#studentCPN").val(doc[2]);
            $("#studentCDD").val(doc[4]);
            $studentCIs.parent().html("<input type=text id=studentCIs>");
            $studentCIs.val(doc[3]);
            $("#studentCAd").val(globObj["fillContacts"][a]["addr"]);
        }
        $("#rollOver").fadeOut(500);
        $("#rollUp").fadeOut(500);
    },

    addStudentContactQuery: function () {

        let arValidateFieldsIds = [
            "studentCF",
            "studentCI",
            "studentCPh",
        ];

        if (legacy.validateFields(arValidateFieldsIds)) {
            let sData = {};
            $(".editTable input[type=text], .editTable select").each(function (index, element) {
                sData[$(element).attr("id")] = $(element).val();
            });

            sData.otv = $("#studentOT").is(':checked') ? "Y" : "";
            sData.sID = globObj["sID"];
            sData.cID = $("#cID").val();

            $.ajax({
                url: legacy.api_folder + "studentsEditContact.php",
                dataType: "json",
                type: "POST",
                data: sData,
                success: function () {
                    globObj["cID"] = 0;
                    legacy.hidePopUp();
                    window.location.reload();
                },
            });
        }
    },

    hidePopUp: function () {
        diary.hidePopup();
    },

    studentFillDogovorsForm: function () {
        globObj["callback"] = "legacy.hidePopUp()";

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);

            $("#popUpHeader").show().text("Добавление договора на обучение");

            $.ajax({
                url: "/templates/studentDogovor.html",
                dataType: "html",
                data: {},
                type: "POST",
                success: function (data) {
                    $("#popUpContent").html(data);

                    $.ajax({
                        url: legacy.api_folder + "dogovorGetCourses.php",
                        dataType: "json",
                        data: {},
                        type: "POST",
                        success: function (data) {
                            globObj["courseList"] = {};
                            for (let a in data) {
                                if (!data.hasOwnProperty(a)) continue;
                                globObj["courseList"]["n" + data[a]["ID"]] = data[a];
                            }
                            let out = "<option value=\"\"></option>";
                            for (let a in data) {
                                if (!data.hasOwnProperty(a)) continue;
                                out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["name"] + "</option>";
                            }
                            $("#dogovorCourse").html(out);
                            $("#dogovorOf").attr("disabled", false);

                            $.ajax({
                                url: legacy.api_folder + "dogovorGetPassports.php",
                                dataType: "json",
                                data: { sID: globObj["sID"] },
                                type: "POST",
                                success: function (data) {
                                    let out = "";
                                    for (let a in data) {
                                        if (!data.hasOwnProperty(a)) continue;
                                        let current = data.a;
                                        out += "<option value=\"" + data[a]["ID"] + "\">" + Utils.concatFio(current.f, current.i, current.o) + " (" + current.document.type + " " + current.document.serial + " " + current.document.number + ", выдан " + current.document.date + ")</option>";
                                    }
                                    $("#dogovorPassport").html(out);
                                },
                            });

                            $.ajax({
                                url: legacy.api_folder + "dogovorGetParams.php",
                                dataType: "json",
                                data: {},
                                type: "POST",
                                success: function (data) {
                                    $("#dogovorNU").val(data["number"]);
                                    $("#dogovorFrom").val(data["dateFrom"]);
                                    $("#dogovorTo").val(data["dateTo"]);
                                },
                            });

                        },
                    });

                },
            });
        });
    },

    getCourseInfo: function (c) {
        c = "n" + c;
        $("#dogovorTH").val(globObj["courseList"][c]["thours"]);
        $("#dogovorMH").val(globObj["courseList"][c]["mhours"]);
        $("#dogovorC").val(globObj["courseList"][c]["cost"]);
    },

    recountCost: function () {
        let courseId = $("#dogovorCourse").val(),
            getById = function (element) {
                return element.ID === courseId;
            },
            course = globObj["courseList"].find(getById),
            newCost = Math.floor(parseInt(course.cost) * (1 - parseInt($("#dogovorOf").val()) / 100));
        $("#dogovorC").val(newCost);
    },

    addDogovorQuery: function (act) {
        let sData = {};
        $(".editTable input[type=text], .editTable select").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });

        sData["sID"] = globObj["sID"];

        $.ajax({
            url: legacy.api_folder + "dogovorAddRecord.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function (data) {
                if (act === "print") {
                    $("body").append("<a href=\"/contracts/print/" + data["info"] + "/\" target=_blank id=\"dogLink\" style=\"font-size:1px;\">договор</a>");
                }
                this.getStudentDetail(globObj["sID"]);
                legacy.hidePopUp();
                if (act === "print") {
                    document.getElementById("dogLink").click();
                    $("#dogLink").remove();
                }
            },
        });
    },

    getRoomsList: function () {
        $.ajax({
            url: legacy.api_folder + "roomsGetList.php",
            dataType: "json",
            data: {},
            type: "POST",
            success: function (data) {
                let out = "";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += num + ". <b>" + data[a]["name"] + "</b><br><small>" + data[a]["addr"] + ", мест: " + data[a]["max"] + "</small><br><br>";
                    num++;
                }
                $pageContent.html(out);
                $contentHeader.show().html("Список аудиторий&nbsp;&nbsp;&nbsp;<a href=\"javascript:void(0);\" class=\"fillLink\" onclick=\"addRoomForm();\">добавить</a>");
            },
        });
    },


    addRoomForm: function () {
        globObj["callback"] = "legacy.hidePopUp()";

        $.ajax({
            url: "/templates/roomAdd.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Добавление аудитории");
                    $("#popUpContent").html(data);
                });
            },
        });
    },

    addRoomQuery: function () {
        let sData = {};
        $(".editTable input[type=text],.editTable select").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });
        $.ajax({
            url: legacy.api_folder + "roomsAddRecord.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function () {
                legacy.hidePopUp();
                legacy.getRoomsList();
            },
        });
    },

    getTeacherDetail: function (tID) {
        globObj["tID"] = tID;
        $.ajax({
            url: "/templates/teacherDetail.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $pageContent.html(data);
                $.ajax({
                    url: legacy.api_folder + "teacherGetDetail.php",
                    dataType: "json",
                    data: { sID: tID },
                    type: "POST",
                    success: function (data) {
                        globObj["tID"] = data["ID"];


                        let editableFields = {
                            "personalBday": "bdate",
                            "personalPhone": "phone",
                            "personalEmail": "mail",
                        };
                        $contentHeader.show().text(data["fioLong"]);

                        // сформируем поля для быстрого редактирования
                        $.each(editableFields, (key, value) => {
                            let $field = $("#" + key);
                            // проверим наличие значения поля в пришедших из аякса данных
                            if (data[value] === "") {
                                new EditableField($field, value, legacy.editTeacherContactField);
                            } else {
                                $field.text(data[value]);
                                if (key === "mail") {
                                    $("#accessButton").on("click", function () {
                                        legacy.emailTeacher(data["mail"]);
                                    });
                                }
                            }
                        });

                        $("#personalPhoto").html("<img src=\"/" + data["avatar"] + "\" width=\"100%\" height=\"100%\" alt=\"аатар педагога\">");

                        if (data["groups"].length > 0) {
                            var out = "<br><table class=\"groupList\"><tr><th>№</th><th>Группа</th><th>Кол-во слушателей</th><th>Отказы</th><th>Расписание</th></tr>";
                            var num = 1;
                            for (let a in data["groups"]) {
                                if (!data["groups"].hasOwnProperty(a)) continue;
                                let group = data["groups"][a];
                                out += `<tr><td>${num}</td><td><a href="/groups/${group["ID"]}/detail/" >${group["name"]}</a></td><td>${group["count"]}</td><td>${data["groups"][a]["denial"]}</td><td>${data["groups"][a]["shedule"]}</td></tr>`;
                                num++;
                            }
                            out += "</table>";
                            $("#activeGroups").html(out);
                        } else {
                            $("#activeGroups").html("<small>отсутствуют</small>");
                        }

                    },
                });
            },
        });
    },

    detailDogovorDeny: function (dID) {
        globObj["callback"] = "legacy.hidePopUp()";
        $.ajax({
            url: "/templates/dogovorDenial.html",
            data: {},
            type: "GET",
            dataType: "html",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Расторжение договора");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "dogovorGetDetail.php",
                        type: "POST",
                        dataType: "json",
                        data: { dID: dID },
                        success: function (data) {
                            globObj["dendID"] = data[0];
                            $("#dogInfo").html("<b>Договор № " + data[2] + " от " + data[3] + "</b><br><b>Заказчик:</b> " + data[5] + " <br><b>Курс:</b> " + data[1]);
                            $("#denyFormLink").attr("href", "/contracts/" + data[0] + "/print_deny/");
                            // $('#denyFormLink').attr('href', '/templates/studentDogovorDeny.php?dog=' + data[0]);
                            $("#denyDate").val(data[4]);
                        },
                    });
                });
            },
        });
    },

    addDenialRecord: function (print) {
        let sData = {};
        $(".editTable input[type=text],.editTable input[type=hidden],.editTable select").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });
        sData.dID = globObj["dendID"];
        $.ajax({
            url: legacy.api_folder + "dogovorDenyRecord.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: () => {
                if (print) {
                    window.open(`/contracts/${sData.dID}/print/`, '_blank');
                }
                location.reload();
            },
        });
    },

    addAdverForm: function () {
        globObj["callback"] = "legacy.hidePopUp()";

        $.ajax({
            url: "/templates/adverAdd.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Добавление рекламы");
                    $("#popUpContent").html(data);
                });
            },
        });
    },

    detailDogovorAdver: function (dID) {
        globObj["callback"] = "legacy.hidePopUp()";
        globObj["dendID"] = dID;
        $.ajax({
            url: "/templates/dogovorAdver.html",
            data: {},
            type: "GET",
            dataType: "html",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Информация по рекламной кампании");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "adverGetList.php",
                        dataType: "json",
                        type: "GET",
                        data: {},
                        success: function (data) {
                            let out = "";
                            data.forEach((adver, index, curArray) => {
                                out += "<div class=\"form-check\">";
                                out += `<input class="form-check-input" type="radio" value="${adver["ID"]}" name="adver" id="advOpt${adver["ID"]}">`;
                                out += `<label class="form-check-label" for="advOpt${adver["ID"]}">${adver["name"]}</label>`;
                                out += "</div>";
                            });
                            $("#adverTypes").html(out);
                        },
                    });
                });
            },
        });
    },

    addDogAdverRecord: function () {
        let sData = {};
        sData["adver"] = $(".editTable input[type='radio']:checked").val();
        sData["dID"] = globObj["dendID"];
        console.log(sData);
        $.ajax({
            url: legacy.api_folder + "dogovorAddAdver.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function () {
                legacy.hidePopUp();
                location.reload();
            },
        });
    },

    groupAddByFamily: function (courseId) {
        globObj["callback"] = "legacy.hidePopUp()";
        $.ajax({
            url: "/templates/dogovorAddByFamily.php",
            dataType: "html",
            data: {
                courseId: courseId
            },
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Добавление студента по фамилии");
                    $("#popUpContent").html(data);
                });
            },
        });
    },

    searchByFIO: function (courseId) {
        let gID = globObj.gID;
        globObj.callback = "legacy.hidePopUp()";
        let query = $("#addBySearch").val();
        if (query == "") {
            return false;
        }
        $.ajax({
            url: legacy.api_folder + "studentSearchByName.php",
            data: { 
                f: query,    
                courseId: courseId    
            },
            dataType: "json",
            type: "POST",
            success: function (data) {
                let out = "";
                data.forEach((item, index) => {
                    out += "<p>";
                    out += index + 1 + " . " + item.fio + "<br>";
                    out += "<small><stromg>Договор № " + item.dog_number + "</strong> от " + item.dog_date + " (" + item.course_name + ") </small><a class=\"fillLink\" href=\"/contracts/" + item.dog_id + "/include_in_group/" + gID + "/\" >включить в группу</a>";
                    out += "</p>";
                });
                $("#stLists").html(out);
            },
        });
    },

    detailDogovorTransfer: function (dID) {
        globObj["callback"] = "legacy.hidePopUp()";
        $.ajax({
            url: "/templates/dogovorTransfer.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Заявление на перевод в группу");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "dogovorGetDetail.php",
                        type: "GET",
                        dataType: "json",
                        data: { dID: dID },
                        success: function (data) {
                            globObj["dendID"] = data[0];
                            $("#dogInfo").html("<b>Договор № " + data[2] + " от " + data[3] + "</b><br><b>Заказчик:</b> " + data[5] + " <br><b>Курс:</b> " + data[1]);
                            $("#denyDate").val(data[4]);
                        },
                    });


                    $.ajax({
                        url: legacy.api_folder + "getCourseList.php",
                        dataType: "json",
                        data: {},
                        type: "GET",
                        success: function (data) {
                            let out = "";
                            for (let a in data["list"]) {
                                if (!data["list"].hasOwnProperty(a)) continue;
                                out += "<option value=\"" + data["list"][a]["ID"] + "\">" + data["list"][a]["name"] + "</option>";
                            }
                            $("#groupC").html(out);
                        },
                    });

                });
            },
        });
    },

    getRoomsSchedule: function () {
        $.ajax({
            url: legacy.api_folder + "roomsSchedule.php",
            dataType: "json",
            data: {},
            type: "POST",
            success: function (data) {
                let rooms = data["rooms"],
                    groups = data["groups"],
                    errors = data["errors"],
                    converse = {
                        "monday": "Понедельник",
                        "tuesday": "Вторник",
                        "wednesday": "Среда",
                        "thursday": "Четверг",
                        "friday": "Пятница",
                        "saturday": "Суббота",
                        "sunday": "Воскресенье",
                    };
                data = data["info"];

                let out = "";

                //if (errors.length > 0) {
                out += "<div class=\"alert alert-danger mb-2\"><b>Пересечение групп:</b><ul>";
                for (let er in errors) {
                    if (!errors.hasOwnProperty(er)) continue;
                    for (let g in errors[er]) {
                        if (!errors[er].hasOwnProperty(g)) continue;
                        let groups = errors[er][g];
                        out += `<li><a href="/groups/${groups[0]}/" >${groups[2]}</a> с <a href="/groups/${groups[3]}/" >${groups[4]}</a></li>`;
                    }
                }
                out += "</ul></div>";

                for (let days in data) {
                    if (!data.hasOwnProperty(days)) continue;
                    out += "<div class=\"innerHeader\">" + converse[days] + "</div>";
                    out += "<table class=\"scheduleTable\">";

                    out += "<tr><th>&nbsp;</th>";
                    for (let c = 9; c < 21; c++) {
                        out += "<th colspan=6>" + c + "</th>";
                    }
                    out += "</tr>";

                    out += "<tr><th>&nbsp;</th>";
                    for (let c = 9; c < 21; c++) {
                        for (let b = 0; b < 6; b++) {
                            out += "<th>" + b + "0</th>";
                        }
                    }
                    out += "</tr>";

                    for (let a in data[days]) {
                        if (!data[days].hasOwnProperty(a)) continue;
                        if (!rooms[a]) {
                            continue;
                        }
                        out += "<tr><th>" + rooms[a].name + "</th>";

                        for (let dc = 9; dc < 21; dc++) {
                            for (let b = 0; b < 6; b++) {
                                if (data[days][a][dc + ":" + b + "0"] > 0) {
                                    let dur = 0,
                                        group = data[days][a][dc + ":" + b + "0"],
                                        lastps = "", ps;

                                    for (let ps in data[days][a]) {
                                        if (!data[days][a].hasOwnProperty(ps)) continue;
                                        if (data[days][a][ps] === group) {
                                            dur++;
                                            lastps = ps;
                                        }
                                    }

                                    ps = lastps.split(":");
                                    ps[1] = parseInt(ps[1]) / 10;

                                    dc = ps[0];
                                    b = ps[1];
                                    try {
                                        out += "<td style=\"font-size:11px;\" colspan=\"" + dur + "\" class=\"text-center\" >" +
                                            "<a class=\"tooltipped\" title=\"'Код: " + groups["g_" + data[days][a][dc + ":" + b + "0"]]["code"] + "<br>Преподаватель: " +
                                            groups["g_" + data[days][a][dc + ":" + b + "0"]]["teacher"]["FIO"] + "<br>Количество: " +
                                            groups["g_" + data[days][a][dc + ":" + b + "0"]]["people"] + "';\" href=\"/groups/" +
                                            groups["g_" + data[days][a][dc + ":" + b + "0"]]["ID"] + "/\" style=\"color: #000;background-color:" +
                                            Utils.getRandomBackground() + "; font-size:11px; display:inline-block; white-space:nowrap; height:100%; width:100%; text-align:center; overflow:hidden;\">" +
                                            groups["g_" + data[days][a][dc + ":" + b + "0"]]["name"] +
                                            " (" + groups["g_" + data[days][a][dc + ":" + b + "0"]]["code"] + ")</a></td>";
                                    } catch (err) {
                                        console.log(err);
                                    }
                                } else if (data[days][a][dc + ":" + b + "0"] === -1) {
                                    out += "<td style=\"font-size:11px; background-color:#999;\">&nbsp;</td>";
                                } else {
                                    out += "<td style=\"font-size:11px;\">&nbsp;</td>";
                                }
                            }
                        }
                        out += "</tr>";
                    }
                    out += "</table><br><br>";
                }

                $pageContent.html(out);
                $contentHeader.show().html("Загруженность кабинетов");
            },
        });
    },

    getAdverStat: function () {
        $.ajax({
            url: legacy.api_folder + "adverGetStat.php",
            dataType: "json",
            data: {},
            type: "GET",
            success: function (data) {
                let $container = $("<div></div>"),
                    newO = {};
                Object.keys(data).sort(
                    (a, b) => {
                        return (data[a].percent > data[b].percent) ? -1 : (data[a].percent < data[b].percent) ? 1 : 0;
                    },
                )
                    .map(key => newO[key] = data[key]);

                $.each(newO, (index, value) => {
                    let $row = $("<div class='row align-items-center'></div>"),
                        $name = $("<div class='col-3 py-2'></div>"),
                        $percentCol = $("<div class='col-9 py-2'></div>"),
                        $percent = $("<div class='py-3'></div>"),
                        $sep = $("<div class='w-100'></div>");
                    $name.html(`${value.name}<br><b>${value.percent / 4} %</b>`);
                    $percent
                        .css("background", Utils.getRandomBackground("setOne"))
                        .css("width", value.percent / 4 + "%");
                    $percentCol.append($percent);
                    $row
                        .append($name)
                        .append($percentCol);
                    $container
                        .append($row);
                });
                $contentHeader.show().html("Статистика по рекламе");
                $pageContent.html($container);
            },
        });
    },

    /**
     * возвращает список договоров, или договор по номеру
     * @param number
     */
    getDogList: function (number) {
        let postData = (number !== undefined) ? { "n": +number } : {};
        $.ajax({
            url: legacy.api_folder + "dogovorsGetList.php",
            dataType: "json",
            type: "POST",
            data: postData,
            success: function (data) {
                $contentHeader.show().html("Список договоров");
                $pageContent.html(legacy.renderContractTable(data, legacy.getDogList));
            },
        });
    },

    getDogListUnincluded: function () {
        $.ajax({
            url: legacy.api_folder + "dogovorsGetListUnincluded.php",
            dataType: "json",
            type: "POST",
            data: {},
            success: function (data) {
                $contentHeader.show().html("Список неприкрепленных договоров");
                $pageContent.html(legacy.renderContractTable(data, legacy.getDogListUnincluded));
            },
        });
    },

    getDogListDenied: function () {
        $.ajax({
            url: legacy.api_folder + "dogovorsGetListDenied.php",
            dataType: "json",
            type: "POST",
            data: {},
            success: function (data) {
                $contentHeader.show().html("Список расторгнутых договоров");
                $pageContent.html(legacy.renderContractTable(data, legacy.getDogListDenied));
            },
        });
    },

    renderContractTable: function (data, context) {
        let $container = $("<div></div>"),
            $div = $("<div class='mb-1'></div>"),
            $searchInput = $("<input class='form-control' type=\"text\" placeholder=\"Поиск по номеру договора...\">"),
            $table = $("<table class=\"table table-striped small\"></table>"),
            $th = $("<tr><th>#</th><th>Слушатель</th><th>Школа/класс/смена</th><th>Родитель</th><th>Курс</th><th>Дата заключения/действия</th><th>Действия</th></tr>"),
            noteText = `Показаны только первые 200 договоров из ${data.dogovorsCount} !`,
            $note = $(`<div class="small text-secondary">${noteText}</div>`),
            $alert = $(`<div class="alert alert-light" role="alert">${noteText}</div>`);
        $div.append($searchInput);
        $container.append($div);
        $container.append($note);
        $table.append($th);
        for (let a in data) {
            if (!data.hasOwnProperty(a) || a === "dogovorsCount") {
                continue;
            }
            let current = data[a];
            $table.append(legacy.renderContractRow(current));
        }
        $container.append($table);
        $container.append($alert);
        $searchInput.on("keypress", legacy.searchDogovors.bind(this, context));
        return $container;
    },

    renderContractRow: function (current) {
        if (!current.student) {
            return;
        }
        let student = current.student,
            parent = current.parent;
        let fio = Utils.concatFio(student.f, student.i, student.o);
        let otv_fio = Utils.concatFio(parent.f, parent.i, parent.o);
        let text = `<tr><td>${current.number}</td>`;
        text += `<td><a href="/students/${student.ID}/">${fio}</a></td>`;
        text += `<td>${student.school} / ${student.class} / ${student.smena} <td>`;
        text += `<td>${otv_fio}</td>`;
        text += `<td>${current.course.name}</td>`;
        text += `<td>с ${current.ts_from}<br>до ${current.ts_to}</td>`;
        text += `<td class="text-center;"><a href="javascript:void(0);" onclick="return false; editDogovor(${current.ID});">редактировать</a><br>`;
        text += `<a href="javascript:void(0);" onclick="return false; deleteDogovor(${current.ID});"  class="text-danger">удалить</a></td>`;
        text += `</tr>`;
        return $(text);
    },

    searchDogovors: function (callback, event) {
        if (event.which === 13) {
            let $input = $(event.target);
            callback($input.val());
        }
    },
    /**
     *
     * @param studentId
     */
    editStudent: function (studentId) {
        globObj["callback"] = "legacy.hidePopUp()";
        if (!studentId) {
            studentId = globObj["sID"];
        } else {
            globObj["sID"] = studentId;
        }
        if (!studentId) {
            throw new this.IdMissingException();
        }
        $.ajax({
            url: "/templates/studentAdd.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Редактирование слушателя");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "studentGetMin.php",
                        dataType: "json",
                        data: { "sID": studentId },
                        type: "POST",
                        success: function (data) {
                            let $btnOk = $("<input type=\"button\" class=\"btn btn-primary me-3\" value=\"сохранить\">"),
                                $btnCancel = $("<input type=\"button\" class=\"btn btn-secondary\" value=\"отменить\">");
                            $("#studentF").val(data.f);
                            $("#studentI").val(data.i);
                            $("#studentO").val(data.o);
                            $("#studentL").val(data.login);
                            $("#studentP").val(data.pass);
                            $("#studentPh").val(data.phone);
                            $("#studentBirthDate").val(data.bdate);
                            $("#studentS").val(data.school);
                            $("#studentC").val(data.class);
                            $("#studentSm").val(data.smena);
                            $("#stButPan").append($btnOk)
                                .append($btnCancel);
                            $btnOk.on("click", legacy.editStudentQuery);
                            $btnCancel.on("click", legacy.globalCallback);
                        },
                    });
                });
            },
        });
    },

    getCurrentGroups: function () {
        $.ajax({
            url: legacy.api_folder + "getCurrentGroups.php",
            dataType: "json",
            type: "POST",
            data: {},
            success: function (data) {

                let out = "<br><table class=\"groupList\"><tr><th>№</th><th>Группа</th><th>Кабинет</th><th>Кол-во слушателей</th><th>Преподаватель</th><th>Расписание</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    let cItem = data[a], bg;
                    if (cItem["current"] === "y") {
                        bg = "#ccffcc";
                    } else {
                        bg = "#ffffff";
                    }
                    let teacherStr = "";
                    if (cItem["teacher"] !== null) {
                        let teacher = cItem.teacher;
                        teacherStr = "<a href=\"javascript:void(0)\" class=\"studentList\" onclick=\"getTeacherDetail(" + teacher["ID"] + ")\">" + Utils.concatFio(teacher.f, teacher.i, teacher.o) + "</a>";
                    }
                    out += "<tr style=\"background-color:" + bg + "\"><td class=\"text-center\">" + num + ".</td><td><a href=\"/groups/" + cItem["ID"] + "/\" class=\"studentList\">" + cItem["name"] + " (" + cItem["code"] + ")</a></td><td>" + cItem["cab"] + "</td><td class=\"text-center\">" + cItem["count"] + "</td><td>" + teacherStr + "</td><td class=\"text-center\">" + cItem["schedule"] + "</td></tr>";
                    num++;
                }
                out += "</table>";
                $pageContent.html(out);
                $contentHeader.show().html("Группы на сегодня");
            },
        });
    },

    meEditGroup: function () {
        editGroup(globObj["gID"]);
    },

    printListGroup: function () {
        $("body").append("<a href=" + globObj["gID"] + "\"/templates/studentsList.php?gr=\" target=_blank id=\"vedLink\" style=\"font-size:1px;\">ведомость</a>");
        $("#vedLink").click()
            .remove();
    },

    editTeacher: function () {
        globObj["callback"] = "legacy.hidePopUp();";
        $.ajax({
            url: "/templates/teacherAdd.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Редактирование преподавателя");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "teacherGetMin.php",
                        dataType: "json",
                        data: { "tID": globObj["tID"] },
                        type: "POST",
                        success: function (data) {
                            $("#teacherF").val(data["f"]);
                            $("#teacherI").val(data["i"]);
                            $("#teacherO").val(data["o"]);
                            $("#teacherL").val(data["login"]);
                            $("#teacherP").val(data["pass"]);
                            $("#teacherPh").val(data["phone"]);
                            $("#teacherE").val(data["mail"]);
                            $("#teButPan").html("<input type=\"button\" class=\"button_ok\" onClick=\"editTeacherQuery();\" value=\"сохранить\"><input type=\"button\" class=\"button_deny\" onClick=\"globalCallback();\" value=\"отменить\">");
                        },
                    });
                });
            },
        });
    },

    editTeacherQuery: function () {
        let arValidateFieldsIds = ["teacherF", "teacherI", "teacherO"];
        if (legacy.validateFields(arValidateFieldsIds)) {
            let sData = {};
            $(".editTable input[type=text]").each(function (index, element) {
                sData[$(element).attr("id")] = $(element).val();
            });
            sData["tID"] = globObj["tID"];
            $.ajax({
                url: legacy.api_folder + "teacherSaveEdit.php",
                dataType: "json",
                type: "POST",
                data: sData,
                success: function () {
                    legacy.hidePopUp();
                    legacy.getTeacherDetail(globObj["tID"]);
                },
            });
        }
    },

    editDogovor: function (ID) {
        let $dogovorDiscount = $("#dogovorOf"),
            out = "";
        globObj["callback"] = "legacy.hidePopUp();";
        $.ajax({
            url: "/templates/studentEditDog.html",
            dataType: "html",
            type: "POST",
            data: {},
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Редактирование договора");
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "dogovorGetCourses.php",
                        dataType: "json",
                        data: {},
                        type: "POST",
                        success: function (data) {
                            globObj["courseList"] = data;
                            out += "<option value=\"\"></option>";
                            for (let a in data) {
                                if (!data.hasOwnProperty(a)) continue;
                                out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["name"] + "</option>";
                            }
                            $("#dogovorCourse").html(out);
                            $dogovorDiscount.attr("disabled", false);

                            $.ajax({
                                url: legacy.api_folder + "dogovorFullInfo.php",
                                dataType: "json",
                                type: "POST",
                                data: { "dID": ID },
                                success: function (data) {
                                    globObj["dEID"] = data["dog"]["ID"];
                                    $("#dogovorNU").val(data["dog"]["number"]);
                                    $("#dogovorCourse").val(data["course"]["ID"]);
                                    $("#dogovorTH").val(data["dog"]["thours"]);
                                    $("#dogovorMH").val(data["dog"]["mhours"]);
                                    $("#dogovorC").val(data["dog"]["cost"]);
                                    $dogovorDiscount.val(data["dog"]["offer"]);
                                    $("#dogovorFrom").val(data["dog"]["ts_from"]);
                                    $("#dogovorTo").val(data["dog"]["ts_to"]);
                                    $("#dogovorPassport").val();

                                    $dogovorDiscount.on("blur", legacy.recountCost());

                                    $.ajax({
                                        url: legacy.api_folder + "dogovorGetPassports.php",
                                        dataType: "json",
                                        data: { sID: data["parent"]["sID"] },
                                        type: "POST",
                                        success: function (data) {
                                            out = "";
                                            for (let a in data) {
                                                if (!data.hasOwnProperty(a)) continue;
                                                let current = data[a];
                                                out += "<option value=\"" + data[a]["ID"] + "\">" + Utils.concatFio(current.f, current.i, current.o) + " (" + current.document.type + " " + current.document.serial + " " + current.document.number + ", выдан " + current.document.date + ")</option>";
                                            }
                                            $("#dogovorPassport").html(out);
                                        },
                                    });
                                },
                            });
                        },
                    });
                });
            },
        });
    },

    selectAgentByFIO: function () {
        globObj["callback"] = "legacy.hidePopUp()";
        $.ajax({
            url: "/templates/contragentSelectFIO.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#rollOver").fadeIn(500);
                $("#rollUp").fadeIn(500);
                $("#rollUpHeader").html("Исправление контрагента");
                $("#rollUpContent").html(data);
            },
        });
    },

    searchByCAFIO: function () {
        $.ajax({
            url: legacy.api_folder + "contragentSelectFIO.php",
            dataType: "json",
            data: { f: $("#addBySearch").val() },
            type: "POST",
            success: function (data) {
                globObj["parIDS"] = data;
                let out = "";
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    let current = data[a];
                    out += "<a href=\"javascript:void(0)\" onclick=\"useCurrentParent(" + a + ")\">" + Utils.concatFio(current.f, current.i, current.o) + " (" + data[a]["document"]["type"] + " " + data[a]["document"]["serial"] + " " + data[a]["document"]["number"] + ", выдан " + data[a]["document"]["date"] + ")</a><br>";
                }
                $("#stLists").html(out);
            },
        });
    },

    useCurrentParent: function (pID) {
        let globalParentAr = globObj.parIDS,
            current = globalParentAr[pID],
            parent;
        parent = "<option value=\"" + current.ID + "\">" + Utils.concatFio(current.f, current.i, current.o) + " (" + current.document.type + " " + current.document.serial + " " + current.document.number + ", выдан " + current.document.date + ")</option>";
        this.hideContactInfo();
        $("#dogovorPassport").html(parent);
    },

    editDogovorQuery: function () {
        let sData = {};
        $(".editTable input[type=text], .editTable select").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });

        sData["dID"] = globObj["dEID"];

        $.ajax({
            url: legacy.api_folder + "dogovorEditRecord.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function (data) {
                legacy.getStudentDetail(data["info"]);
                legacy.hidePopUp();
            },
        });
    },

    deleteDogovor: function (ID) {
        if (confirm("Договор будет удален!")) {
            $.ajax({
                url: legacy.api_folder + "dogovorDeleteRecord.php",
                dataType: "json",
                type: "POST",
                data: { dID: ID },
                success: function () {
                    let $searchDogovor = $("#searchDog"),
                        val = $searchDogovor.val();
                    if (val !== "") {
                        this.getDogList(val);
                    } else {
                        this.getDogList();
                    }
                },
            });
        }
    },

    excludeFromGroup: function (dID, gID) {
        if (gID === undefined) {
            gID = globObj["gID"];
        }
        $.ajax({
            url: legacy.api_folder + "dogovorDeleteFromGroup.php",
            dataType: "json",
            type: "POST",
            data: { dID: dID },
            success: function () {
                diary.getData("groups.detail", "", "", gID);
            },
        });
    },

    getTeacherGroupsList: function () {
        $.ajax({
            url: legacy.api_folder + "teacherGetGroupsList.php",
            dataType: "json",
            type: "GET",
            data: {},
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>#</th><th>Группа</th><th>Код</th><th>Слушатели</th><th>Расписание</th><th>Дата запуска</th><th>Дата окончания</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + num + "</td><td><a href=\"javascript:void(0)\" onclick=\"getTeacherGroupDetail(" + data[a]["ID"] + ");\" class=\"studentList\">" + data[a]["name"] + "</a></td><td>" + data[a]["code"] + "</td><td>" + data[a]["count"][0] + "</td><td>" + data[a]["shedule"] + "</td><td>" + data[a]["date_start"] + "</td><td>" + data[a]["date_end"] + "</td></tr>";
                    num++;
                }
                out += "</table>";
                $pageContent.html(out);
            },
        });
        $contentHeader.show().html("Список групп");
    },

    getTeacherGroupDetail: function (gID) {
        globObj["gID"] = gID;
        $.ajax({
            url: "/templates/teacherGroupDetail.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $pageContent.html(data);
                $.ajax({
                    url: legacy.api_folder + "groupGetDetails.php",
                    dataType: "json",
                    type: "POST",
                    data: { gID: gID },
                    success: function (data) {
                        $contentHeader.show().html(data["name"]);
                        $("#groupCode").text(data["code"]);
                        $("#groupStart").text(data["date_start"]);
                        $("#groupEnd").text(data["date_end"]);
                        $("#groupShedule").text(data["shedule"]);
                        $.ajax({
                            url: legacy.api_folder + "groupGetIncludedDogs.php",
                            dataType: "json",
                            type: "POST",
                            data: { gID: gID },
                            success: function (data) {
                                let out = "<table class=\"groupList\">";
                                out += "<tr><th style=\"width: 30px;\">#</th><th>ФИО слушателя</th><th>Контакты:</th><th>Действия</th></tr>";
                                let num = 1;
                                for (let a in data) {
                                    if (!data.hasOwnProperty(a)) continue;
                                    let current = data[a],
                                        student = current.student;
                                    out += "<tr><td class=\"text-center\">" + num + ".</td><td><a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"getStudentDetail(" + student["ID"] + ");\">" + Utils.concatFio(student.f, student.i, student.o) + "</a></td><td>";
                                    for (let b in data[a]["contacts"]) {
                                        if (!data[a]["contacts"].hasOwnProperty(b)) continue;
                                        let current = data[a]["contacts"][b];
                                        if (parseInt(b) > 0) {
                                            out += "<br>";
                                        }
                                        out += "<small><b>" + Utils.concatFio(current.f, current.i, current.o) + "</b><br>" + current.phone + "</small>";
                                    }
                                    out += "</td><td>&nbsp;</td></tr>";
                                    num++;
                                }
                                out += "</table>";
                                $("#studentList").html(out);
                                legacy.getThemesVisits();
                                legacy.getDiaryRecord();
                                legacy.getDiaryTests();
                            },
                        });
                    },
                });
            },
        });
    },

    getDiaryRecord: function () {
        $.ajax({
            url: legacy.api_folder + "diaryGetThemes.php",
            dataType: "json",
            type: "POST",
            data: { gID: globObj["gID"] },
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>Дата</th><th>Тема занятия</th><th>Посещаемость</th><th>Кол-во часов</th><th>Действия</th></tr>";
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + data[a]["ts"] + "</td><td>" + data[a]["theme"] + "</td><td>" + data[a]["visit"] + "</td><td>" + data[a]["hours"] + "</td><td>" + data[a]["act"] + "</td></tr>";
                }
                out += "</table>";
                $("#themesList").html(out);
            },
        });
    },

    // TODO - необходимо, чтобы педагог определялся по залогиненному user, на случай подмены
    addThemeAndVisits: function () {
        let arValidateFieldsIds = ["lessonT", "lessonD", "lessonH"];
        if (!legacy.validateFields(arValidateFieldsIds)) {
            return false;
        }
        let sData = {};
        $(".editTable input[type=text]").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });

        sData.gID = $("#gID").val();
        sData.tID = $("#tID").val();
        sData.visit = {};

        $(".editTable input[type=checkbox]").each(function (index, element) {
            sData["visit"][$(element).attr("id")] = $(element).prop("checked");
        });

        $.ajax({
            url: legacy.api_folder + "addThemeAndVisits.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function () {
                legacy.hidePopUp();
                legacy.getThemesVisits();
                legacy.getDiaryRecord();
            },
            error: function (error) {
                console.log(error);
                throw new Error("Не удалсь выполнить запрос на запись!");
            },
        });
    },

    editDiaryRecord: function () {
        let sData = {};
        $(".editTable input[type=text]").each(function (index, element) {
            sData[$(element).attr("id")] = $(element).val();
        });

        sData["visit"] = {};

        $(".editTable input[type=checkbox]").each(function (index, element) {
            sData["visit"][$(element).attr("id")] = $(element).prop("checked");
        });

        sData["tID"] = globObj["tID"];

        $.ajax({
            url: legacy.api_folder + "diaryEditRecord.php",
            dataType: "json",
            type: "POST",
            data: sData,
            success: function () {
                legacy.hidePopUp();
                legacy.getThemesVisits();
                legacy.getDiaryRecord();
            },
        });
    },

    editDiaryTheme: function (ID) {
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/diaryAddRecord.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#popUpHeader").show().html("Редактирование записи");
                $("#popUpContent").html(data);
                globObj["callback"] = "legacy.hidePopUp()";
                $.ajax({
                    url: legacy.api_folder + "groupGetIncludedDogs.php",
                    dataType: "json",
                    type: "POST",
                    data: { gID: globObj["gID"] },
                    success: function (data) {
                        let studentList = legacy.renderStudentListForTheme(data);
                        $("#stLesList").html(studentList);

                        $.ajax({
                            url: legacy.api_folder + "diaryGetTheme.php",
                            dataType: "json",
                            type: "POST",
                            data: { tID: ID },
                            success: function (data2) {
                                $("#gID").val(data2.gID);
                                $("#tID").val(ID);
                                $("#lessonT").val(data2["theme"]);
                                $("#lessonD").val(data2["ts"]);
                                $("#lessonH").val(data2["hours"]);

                                $.each(data2.visit, (key, value) => {
                                    if (value === "false") {
                                        $("#" + key).prop("checked", false);
                                    }
                                });
                            },
                        });
                    },
                });
            },
        });
    },

    renderStudentListForTheme: (arDogovors) => {
        let out = "<ul class='list-group'>";
        $.each(arDogovors, (key, value) => {
            let student = value.student,
                fio = Utils.concatFio(student.f, student.i, student.o);
            out += `<li class="list-group-item d-flex justify-content-between align-items-center">${fio} <input type="checkbox" id="st_${value.dID}" checked></li>`;
        });
        out += "</ul>";
        return out;
    },

    emailTeacher: function (mail) {
        $.ajax({
            url: legacy.api_folder + "teacherSendAccess.php",
            dataType: "json",
            type: "POST",
            data: { mail: mail },
            success: function () {
                $("#accessButton").val("отправлено");
            },
        });
    },

    deleteDiaryTheme: function (ID) {
        if (confirm("Действительно удалить запись?")) {
            $.ajax({
                url: legacy.api_folder + "diaryDeleteRecord.php",
                dataType: "json",
                type: "POST",
                data: { tID: ID },
                success: function () {
                    legacy.getDiaryRecord();
                    legacy.getThemesVisits();
                },
            });
        }
    },

    getThemesVisits: function () {
        $.ajax({
            url: legacy.api_folder + "diaryGetVisits.php",
            dataType: "json",
            type: "POST",
            data: { gID: globObj["gID"] },
            success: function (data) {
                let out = "<table class=\"groupList\" ><tr><th>ФИО</th>";
                for (let a in data["visit"]) {
                    if (!data["visit"].hasOwnProperty(a)) continue;
                    out += "<th>" + a + "</th>";
                }

                out += "</tr>";

                for (let b in data["student"]) {
                    if (!data["student"].hasOwnProperty(b)) continue;
                    out += "<tr><td>" + data["student"][b] + "</td>";
                    for (let c in data["visit"]) {
                        if (!data["visit"].hasOwnProperty(c)) continue;
                        if (data["visit"][c][b] === "false") {
                            out += "<td style=\"text-align: center; color: #900;\"><b>-</b></td>";
                        } else if (data["visit"][c][b] === "true") {
                            out += "<td style=\"text-align: center; color: #900;\"><b>+</b></td>";
                        } else {
                            out += "<td style=\"text-align: center;\"><b>x</b></td>";
                        }
                    }
                    out += "</tr>";
                }

                out += "</table>";

                $("#visitList").html(out);
            },
        });
    },

    searchCourseByName: function () {
        $("#rollOver").fadeIn(500);
        $("#rollUp").fadeIn(500);
        $("#rollUpHeader").html("Поиск курса по названию");
        let out = "<table class=\"editTable\">" +
            "<tr><td colspan=\"2\"><input type=\"text\" id=\"courseNamePart\" placeholder=\"Полное название или часть названия курса\" style=\"width:92%;\" /><input type=\"button\" class=\"fButt\" onClick=\"searchCourseByPartName();\" value=\"ОК\"></td></tr>" +
            "<tr><td colspan=\"2\" id=\"foundCoursesByName\" style=\"max-height:500px; overflow:auto;\"></td></tr>  " +
            "<tr><td colspan=\"2\" class=\"text-center\"><input type=\"button\" class=\"button_deny\" onClick=\"closeSearchCourseByName();\" value=\"закрыть\"></td></tr></table>";
        $("#rollUpContent").html(out);
    },

    closeSearchCourseByName: function () {
        $("#rollOver").fadeOut(500);
        $("#rollUp").fadeOut(500);
    },

    searchCourseByPartName: function () {
        $.ajax({
            url: legacy.api_folder + "coursesSearchByPartName.php",
            dataType: "json",
            type: "POST",
            data: { "name": $("#courseNamePart").val() },
            success: function (data) {
                let out = "";
                if (data.length > 0) {
                    out = "<table>";
                    for (let a in data) {
                        if (!data.hasOwnProperty(a)) continue;
                        out += "<tr><td>" + data[a]["name"] + "</td><td><a href=\"javascript:void(0);\" onclick=\"useThisCourse(" + data[a]["ID"] + ")\">выбрать</a></td></tr>";
                    }
                    out += "</table>";
                } else {
                    out = "<div class=\"alert alert-info\"><b>курсы не найдены</b></div>";
                }
                $("#foundCoursesByName").html(out);
            },
        });
    },

    useThisCourse: function (ID) {
        $("#dogovorCourse").val(ID);
        legacy.getCourseInfo(ID);
        $("#rollOver").fadeOut(500);
        $("#rollUp").fadeOut(500);
    },

    getCourseDetail: function (ID) {
        globObj["courseForModule"] = ID;
        $.ajax({
            url: "/templates/courseDetail.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $contentHeader.show().html("Описание образовательной программы");
                $pageContent.html(data);
                $.ajax({
                    url: legacy.api_folder + "getCourseFullInfo.php",
                    dataType: "json",
                    data: { cID: ID },
                    type: "POST",
                    success: function (data) {
                        $("#fullName").html(data["name"]);
                        $("#shortName").html(data["shortName"]);
                        $("#eduLong").html(data["long"]);
                        $("#siteLink").html("<a href=\"" + data["link"] + "\" class=\"studentList\" target=_blank>" + data["link"] + "</a>");
                        $("#totalStudents").html(data["totalStudents"]);
                        $("#currentStudents").html(data["currentStudents"]);
                        let groupsOut = "";
                        for (let g in data["groups"]) {
                            if (!data["groups"].hasOwnProperty(g)) continue;
                            let year;
                            if (data["groups"][g]["year"] === 1) {
                                year = "<span style=\"font-size:13px; color:#090\">(1 год обучения)</span>";
                            } else if (data["groups"][g]["year"] === 2) {
                                year = "<span style=\"font-size:13px; color:#900\">(2 год обучения)</span>";
                            } else if (data["groups"][g]["year"] === 3) {
                                year = "<span style=\"font-size:13px; color:#00B\">(3 год обучения)</span>";
                            } else {
                                year = "";
                            }
                            groupsOut += `<a href="/groups/${data["groups"][g]["ID"]}/detail/">${data["groups"][g]["name"]} (${data["groups"][g]["code"]})</a> ${year}<br>`;
                            groupsOut += "<strong>Преподаватель:</strong> " + data["groups"][g]["teacher"]["FIO"];
                            for (let d in data["groups"][g]["days"]) {
                                if (!data["groups"][g]["days"].hasOwnProperty(d)) continue;
                                if (data["groups"][g]["days"][d].length > 0) {
                                    for (let a in data["groups"][g]["days"][d]) {
                                        if (!data["groups"][g]["days"][d].hasOwnProperty(a)) continue;
                                        groupsOut += "<li style=\"margin-left:5px;\"><b>" + legacy.scheduleDays[d] + "</b><br><small style=\"display:inline-block; margin-left:15px;\"><strong>Кабинет:</strong> " + data["groups"][g]["days"][d][a][0]["name"] + "<br><strong>Время:</strong>" + data["groups"][g]["days"][d][a][1] + "</small></li>";
                                    }
                                }
                            }
                            groupsOut += "<br>";
                        }

                        if (groupsOut === "") {
                            groupsOut = "Активные группы отсутствуют";
                        }
                        $("#activeGroups").html("<br>" + groupsOut);
                        legacy.courseGetAdverEffects(ID);
                        legacy.courseGetPlane();
                    },
                });
            },
        });
    },

    addModuleToCourse: function () {
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/courseModuleAdd.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#popUpHeader").show().html("Добавление модуля");
                $("#popUpContent").html(data);
                globObj["callback"] = "legacy.hidePopUp()";
            },
        });
    },

    courseAddModule: function () {
        $.ajax({
            url: legacy.api_folder + "courseAddModule.php",
            dataType: "json",
            type: "POST",
            data: { cID: globObj["courseForModule"], name: $("#moduleN").val() },
            success: function () {
                legacy.courseGetPlane();
                legacy.globalCallback();
            },
        });
    },

    courseGetPlane: function () {
        $.ajax({
            url: legacy.api_folder + "courseGetModules.php",
            data: { cID: globObj["courseForModule"] },
            dataType: "json",
            type: "POST",
            success: function (data) {
                let out = "<table class=\"groupList\" ><tr><th style=\"width: 30px;\">#</th><th>Содержание занятия</th><th>Действия</th></tr>";
                let lNum = 1;
                for (let m in data["modules"]) {
                    if (!data["modules"].hasOwnProperty(m)) continue;
                    out += "<tr><th colspan=3>" + data["modules"][m]["name"] + "  &nbsp;&nbsp;&nbsp;<a href=\"javascript:void(0);\" class=\"fillLink\" onClick=\"addThemeToModule(" + data["modules"][m]["ID"] + ");\">добавить урок в модуль</a></th></tr>";
                    for (let t in data["modules"][m]["lessons"]) {
                        if (!data["modules"][m]["lessons"].hasOwnProperty(t)) continue;
                        out += "<tr><td class=\"text-center\">" + lNum + "</td><td><small><b>Теория</b>:<br>" + data["modules"][m]["lessons"][t]["theory"] + "<br>Документы:<br>";
                        for (let d in data["modules"][m]["lessons"][t]["documents"]["l"]) {
                            if (!data["modules"][m]["lessons"][t]["documents"]["l"].hasOwnProperty(d)) continue;
                            out += data["modules"][m]["lessons"][t]["documents"]["l"][d]["name"] + " [<a href=\"http://student.ecit.ru/upload/" + data["modules"][m]["lessons"][t]["documents"]["l"][d]["path"] + "\" class=\"studentList\" style=\"color:#090;\" target=_blank>посмотреть</a>&nbsp;|&nbsp;<a href=\"javascript:void(0)\" onclick=\"deleteFile(" + data["modules"][m]["lessons"][t]["documents"]["l"][d]["ID"] + ");\" class=\"studentList\" style=\"color:#900;\">удалить</a>]<br>";
                        }
                        out += "<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"documentLoadForm(" + data["modules"][m]["lessons"][t]["ID"] + ",1);\">добавить</a>";

                        out += "<br><br><b>Практика:</b><br>" + data["modules"][m]["lessons"][t]["practice"] + "<br>Документы:<br>";
                        for (let d in data["modules"][m]["lessons"][t]["documents"]["t"]) {
                            if (!data["modules"][m]["lessons"][t]["documents"]["t"].hasOwnProperty(d)) continue;
                            out += data["modules"][m]["lessons"][t]["documents"]["t"][d]["name"] + " [<a href=\"http://student.ecit.ru/upload/" + data["modules"][m]["lessons"][t]["documents"]["t"][d]["path"] + "\" class=\"studentList\" style=\"color:#090;\" target=_blank>посмотреть</a>&nbsp;|&nbsp;<a href=\"javascript:void(0)\" onclick=\"deleteFile(" + data["modules"][m]["lessons"][t]["documents"]["t"][d]["ID"] + ");\" class=\"studentList\" style=\"color:#900;\">удалить</a>]<br>";
                        }
                        out += "<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"documentLoadForm(" + data["modules"][m]["lessons"][t]["ID"] + ",2);\">добавить</a>";


                        out += "<br><br><b>Презентации:</b><br>";
                        for (let d in data["modules"][m]["lessons"][t]["presentations"]) {
                            if (!data["modules"][m]["lessons"][t]["presentations"].hasOwnProperty(d)) continue;
                            out += data["modules"][m]["lessons"][t]["presentations"][d]["name"] + " [<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"presentationWatch( " + data["modules"][m]["lessons"][t]["presentations"][d]["ID"] + ");\" style=\"color:#090;\" target=_blank>просмотреть</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"presentationEdit( " + data["modules"][m]["lessons"][t]["presentations"][d]["ID"] + ");\" target=_blank>редактировать</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href=\"javascript:void(0)\" onclick=\"presentationDelete(" + data["modules"][m]["lessons"][t]["presentations"][d]["ID"] + ");\" class=\"studentList\" style=\"color:#900;\">удалить</a>]<br>";
                        }
                        out += "<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"documentCreatePresentation(" + data["modules"][m]["lessons"][t]["ID"] + ");\">создать презентацию</a>";

                        out += "<br><br><b>Проверка знаний:</b><br>";
                        for (let d in data["modules"][m]["lessons"][t]["test"]) {
                            if (!data["modules"][m]["lessons"][t]["test"].hasOwnProperty(d)) continue;
                            out += data["modules"][m]["lessons"][t]["test"][d]["name"] + " [<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"practiceWatch( " + data["modules"][m]["lessons"][t]["test"][d]["ID"] + ");\" style=\"color:#090;\" target=_blank>просмотреть</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"practiceEdit( " + data["modules"][m]["lessons"][t]["test"][d]["ID"] + ");\" target=_blank>редактировать</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href=\"javascript:void(0)\" onclick=\"practiceDelete(" + data["modules"][m]["lessons"][t]["test"][d]["ID"] + ");\" class=\"studentList\" style=\"color:#900;\">удалить</a>]<br>";
                        }
                        out += "<a href=\"javascript:void(0);\" class=\"studentList\" onclick=\"documentCreatePractice(" + data["modules"][m]["lessons"][t]["ID"] + ");\">создать тест</a>";

                        out += "</small></td><td class=\"text-center\"><a href=\"javascript:void(0);\" onclick=\"editLesson(" + data["modules"][m]["lessons"][t]["ID"] + ");\"  class=\"fillLink\">редактировать</a><br><a href=\"javascript:void(0);\" onclick=\"deleteLesson(" + data["modules"][m]["lessons"][t]["ID"] + ");\"  class=\"fillLink\" style=\"color:#900;\">удалить</a></td></tr>";
                        lNum++;
                    }
                }
                out += "</table>";
                $("#themesPlan").html(out);
            },
        });
    },

    documentCreatePresentation: function (lID) {
        globObj["lessonForPresent"] = lID;
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/coursePresentationCreate.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                globObj["callback"] = "legacy.hidePopUp()";
                $("#popUpHeader").show().html("Создание презентации");
                $("#popUpContent").html(data);
            },
        });
    },

    documentCreatePractice: function (lID) {
        globObj["lessonForPractice"] = lID;
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/coursePracticeCreate.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                globObj["callback"] = "legacy.hidePopUp()";
                $("#popUpHeader").show().html("Создание проверки знаний");
                $("#popUpContent").html(data);
            },
        });
    },

    practiceAdd: function () {
        $.ajax({
            url: legacy.api_folder + "practiceAdd.php",
            data: {
                cID: globObj["courseForModule"],
                lID: globObj["lessonForPractice"],
                name: $("#practiceN").val(),
                desc: $("#practiceDescription").val(),
            },
            dataType: "json",
            type: "POST",
            success: function () {
                legacy.courseGetPlane();
                legacy.globalCallback();
            },
        });
    },

    practiceEdit: function (pID) {
        globObj["practice"] = pID;
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/practiceEdit.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                globObj["callback"] = "legacy.hidePopUp()";
                $("#popUpHeader").show().html("Редактирование теста");
                $("#popUpContent").html(data);
                this.getPracticeExcercises(pID);
            },
        });
    },

    practiceAddQuestion: function () {
        $("#rollOver").fadeIn(500);
        $("#rollUp").fadeIn(500);
        $("#rollUpHeader").html("Добавление вопроса");
        let out = "<table class=\"editTable\" id=\"practiceFields\">";
        out += "<tr><td style=\"width: 100px;\" colspan=2>Текст вопроса:</td></tr><tr><td colspan=2><textarea id=\"practiceText\"></textarea></td></tr>";
        out += "<tr><td style=\"width: 100px;\">Тип ответа</td><td><select id=\"practiceType\" onchange=\"practiceFieldDraw(this.value)\"><option value=\"none\"></option><option value=\"single\">один из множества</option><option value=\"multy\">несколько значений</option><option value=\"text\">текстовый ответ</option></select></td></tr>";
        out += "<tr><td style=\"width: 100px;\" colspan=2>Варианты ответа:</td></tr><tr><td colspan=2 id=\"practiceForm\"></td></tr>";

        out += "<tr><td colspan=2 class=\"text-center\"><input type=\"button\" onClick=\"practiceWriteQuestion();\" class=\"button_ok\" value=\"сохранить\"> <input type=\"button\" class=\"button_deny\" onClick=\"hideContactInfo();\" value=\"отменить\"></td></tr>";
        out += "</table>";
        $("#rollUpContent").html(out);
    },

    practiceFieldDraw: function (type) {
        let out = "<table>",
            $practiceForm = $("#practiceForm");
        $practiceForm.html("");
        if (type === "single") {
            for (let a = 0; a < 5; a++) {
                out += "<tr><td style=\"width: 30px;\"><input type=\"radio\" name=\"variant\" id=\"practiceVar[" + a + "]\"></td><td><input type=\"text\" id=\"practiceDesc[" + a + "]\"></td></tr>";
            }
        } else if (type === "multy") {
            for (let a = 0; a < 5; a++) {
                out += "<tr><td style=\"width: 30px;\"><input type=\"checkbox\" name=\"variant\" id=\"practiceVar[" + a + "]\"></td><td><input type=\"text\" id=\"practiceDesc[" + a + "]\"></td></tr>";
            }
        } else if (type === "text") {
            $practiceForm.html("<input type=\"text\" id=\"practiceDesc\">");
            return;
        }
        out += "</table>";
        $practiceForm.html(out);
    },

    practiceWriteQuestion: function () {
        let data = {};

        $("#practiceForm input").each(function (index, element) {
            data[$(element).attr("id")] = {
                type: $(element).attr("type"),
                val: $(element).val(),
                check: ($(element).prop("checked") === true) ? "yes" : "no",
            };
        });

        data["practiceText"] = $("#practiceText").val();
        data["practiceType"] = $("#practiceType").val();

        data["pID"] = globObj["practice"];
        $.ajax({
            url: legacy.api_folder + "practiceAddQuestion.php",
            dataType: "json",
            data: data,
            type: "POST",
            success: function () {
                $("#rollOver").fadeOut(500);
                $("#rollUp").fadeOut(500);
                legacy.getPracticeExcercises(globObj["practice"]);
            },
        });
    },

    getPracticeExcercises: function (pID) {
        $.ajax({
            url: legacy.api_folder + "practiceGetQuestions.php",
            data: { pID: pID },
            dataType: "json",
            type: "POST",
            success: function (data) {
                let out = "";
                $("#pName").html("<b>" + data["pr"]["name"] + "</b>");
                let num = 0;
                for (let s in data["questions"]) {
                    if (!data["questions"].hasOwnProperty(s)) continue;
                    num++;
                    out += "<b>" + num + "</b>. " + data["questions"][s]["text"] + "<br>";
                }
                $("#pSlides").html(out);
            },
        });
    },

    presentationAdd: function () {
        $.ajax({
            url: legacy.api_folder + "presentationAdd.php",
            data: { cID: globObj["courseForModule"], lID: globObj["lessonForPresent"], name: $("#presentN").val() },
            dataType: "json",
            type: "POST",
            success: function () {
                legacy.courseGetPlane();
                legacy.globalCallback();
            },
        });
    },

    presentationEdit: function (pID) {
        globObj["presentation"] = pID;
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/presentationEdit.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                globObj["callback"] = "legacy.hidePopUp()";
                $("#popUpHeader").show().html("Редактирование презентации");
                $("#popUpContent").html(data);
                legacy.getPresentationSlides(pID);
            },
        });
    },

    presentationAddSlide: function () {
        $("#rollOver").fadeIn(500);
        $("#rollUp").fadeIn(500);
        $("#rollUpHeader").html("Добавление слайда");
        let out = "<table class=\"editTable\" id=\"slideLoad\">";
        out += "<tr><td style=\"width: 100px;\">Название</td><td><input type=\"text\" id=\"slideName\"></td></tr>";
        out += "<tr><td style=\"width: 100px;\">Порядок</td><td><input type=\"text\" id=\"slideOrder\"></td></tr>";
        out += "<tr><td style=\"width: 100px;\">Файл слайда</td><td><input type=\"file\" id=\"slideDoc\"></td></tr>";

        out += "<tr><td colspan=2 class=\"text-center\"><input type=\"submit\" class=\"button_ok\" value=\"сохранить\"> <input type=\"button\" class=\"button_deny\" onClick=\"hideContactInfo();\" value=\"отменить\"></td></tr>";
        out += "</table>";
        $("#rollUpContent").html(out);

        let files;
        $("#slideLoad input[type=file]").change(function () {
            files = this.files;
        });

        $("#slideLoad input[type=submit]").click(function (event) {
            event.stopPropagation();
            event.preventDefault();
            let data = new FormData();
            $.each(files, function (key, value) {
                data.append(key, value);
            });

            $.ajax({
                url: legacy.api_folder + "courseLoadDocument.php",
                type: "POST",
                data: data,
                cache: false,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {

                    $.ajax({
                        url: legacy.api_folder + "presentationAddSlide.php",
                        dataType: "json",
                        data: {
                            image: data[0],
                            name: $("#slideName").val(),
                            order: $("#slideOrder").val(),
                            pID: globObj["presentation"],
                        },
                        type: "POST",
                        success: function () {
                            $("#rollOver").fadeOut(500);
                            $("#rollUp").fadeOut(500);
                            legacy.getPresentationSlides(globObj["presentation"]);
                        },
                    });
                },
            });
        });
    },

    getPresentationSlides: function (pID) {
        $.ajax({
            url: legacy.api_folder + "presentationGetSlides.php",
            data: { pID: pID },
            dataType: "json",
            type: "POST",
            success: function (data) {
                let out = "";
                $("#pName").html("<b>" + data["pr"]["name"] + "</b>");
                for (let s in data["slides"]) {
                    if (!data["slides"].hasOwnProperty(s)) continue;
                    out += "<div style=\"margin:5px; border:1px solid #EEE; display:inline-block; width:250px; height:130px; background-image:url(http://student.ecit.ru/upload/" + data["slides"][s]["prev"] + ");background-size:cover; background-repeat:no-repeat\"></div>";
                }
                $("#pSlides").html(out);
            },
        });
    },

    presentationWatch: function (pID) {
        $.ajax({
            url: legacy.api_folder + "presentationGetSlides.php",
            data: { pID: pID },
            dataType: "json",
            type: "POST",
            success: function (data) {
                let $rollOver = $("#rollOver");
                globObj["slides"] = data["slides"];
                globObj["keySlide"] = 0;
                $rollOver.on("click", function () {
                    legacy.hidePopUp();
                });

                $rollOver.fadeIn(500);
                $("#slideFrame").attr("src", "http://student.ecit.ru/upload/" + data["slides"][0]["image"]);
                $("#slideName").html(data["slides"][0]["name"]);
                $("#slideOver").fadeIn(500);
            },
        });
    },

    presentationNext: function () {
        if (globObj["slides"].length > globObj["keySlide"] + 1) {
            globObj["keySlide"]++;
            $("#slideFrame").attr("src", "http://student.ecit.ru/upload/" + globObj["slides"][globObj["keySlide"]]["image"]);
            $("#slideName").html(globObj["slides"][globObj["keySlide"]]["name"]);
        }
    },

    presentationPrev: function () {
        if (globObj["keySlide"] > 0) {
            globObj["keySlide"]--;
            $("#slideFrame").attr("src", "http://student.ecit.ru/upload/" + globObj["slides"][globObj["keySlide"]]["image"]);
            $("#slideName").html(globObj["slides"][globObj["keySlide"]]["name"]);
        }
    },

    presentationDelete: function (pID) {
        if (confirm("Презентация и все слайды будут удалены!")) {
            $.ajax({
                url: legacy.api_folder + "presentationDelete.php",
                dataType: "json",
                data: { pID: pID },
                type: "POST",
                success: function () {
                    legacy.courseGetPlane();
                },
            });
        }
    },

    addThemeToModule: function (mID) {
        globObj["moduleForLesson"] = mID;
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });
        $.ajax({
            url: "/templates/courseAddLesson.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                globObj["callback"] = "legacy.hidePopUp()";
                $("#popUpHeader").show().html("Добавление урока в модуль");
                $("#popUpContent").html(data);
            },
        });
    },

    addLessonToModule: function () {
        $.ajax({
            url: legacy.api_folder + "courseAddLessonToModule.php",
            data: {
                cID: globObj["courseForModule"],
                mID: globObj["moduleForLesson"],
                theory: $("#lessonTheory").val(),
                practice: $("#lessonPractice").val(),
            },
            dataType: "json",
            type: "POST",
            success: function () {
                legacy.courseGetPlane();
                legacy.globalCallback();
            },
        });
    },

    documentLoadForm: function (lID, type) {
        globObj["lessonID"] = lID;
        globObj["docType"] = type;

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });

        $.ajax({
            url: "/templates/lessonAddDocument.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#popUpHeader").show().html("Добавление документа к уроку");
                $("#popUpContent").html(data);
                globObj["callback"] = "legacy.hidePopUp()";
                let files;
                $("#docLoad input[type=file]").change(function () {
                    files = this.files;
                });

                $("#docLoad input[type=submit]").click(function (event) {
                    event.stopPropagation();
                    event.preventDefault();
                    let data = new FormData();
                    $.each(files, function (key, value) {
                        data.append(key, value);
                    });

                    $.ajax({
                        url: legacy.api_folder + "courseLoadDocument.php",
                        type: "POST",
                        data: data,
                        cache: false,
                        dataType: "json",
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            legacy.courseLoadDocument(data);
                        },
                    });
                });
            },
        });
    },

    courseLoadDocument: function (data) {
        $.ajax({
            url: legacy.api_folder + "courseSaveDocument.php",
            dataType: "json",
            data: {
                file: data[0],
                name: $("#docName").val(),
                desc: $("#docDesc").val(),
                type: globObj["docType"],
                lID: globObj["lessonID"],
                cID: globObj["courseForModule"],
            },
            type: "POST",
            success: function () {
                legacy.globalCallback();
                legacy.courseGetPlane();
            },
        });
    },

    courseGetAdverEffects: function (ID) {
        $.ajax({
            url: legacy.api_folder + "courseGetAdverEffect.php",
            dataType: "json",
            data: { cID: ID },
            type: "POST",
            success: function (data) {
                let out = "<br><table class=\"groupList\" >";
                for (let a in data["adver"]) {
                    if (!data["adver"].hasOwnProperty(a)) continue;
                    let percent;
                    if (data["total"] > 0) {
                        percent = Math.floor(data["adver"][a][1] / data["total"] * 100);
                    } else {
                        percent = 0;
                    }
                    out += "<tr><td>" + data["adver"][a][0] + "</td><td style=\"width: 300px;\"><div style=\"background-color:" + Utils.getRandomBackground("setTwo") + "; height:20px; width:" + (percent * 3) + "px;\"></td><td>" + data["adver"][a][1] + " (" + percent + "%)</td></tr>";
                }
                out += "</table>";
                $("#adverResults").html(out);
            },
        });
    },

    deleteFile: function (ID) {
        if (confirm("Файл будет удален!")) {
            $.ajax({
                url: legacy.api_folder + "courseDeleteFile.php",
                dataType: "json",
                data: { ID: ID },
                type: "POST",
                success: function () {
                    legacy.courseGetPlane();
                },
            });
        }
    },

    sendToGroup: function () {
        $contentHeader.show().html("Рассылка информации по группам &nbsp;&nbsp;&nbsp;<a href=\"javascript:void(0);\" class=\"fillLink\" onClick=\"groupMessageMaster();\">мастер создания рассылки</a>");
        $pageContent.html("<br>история отсутствует");
    },

    groupMessageMaster: function () {
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
        });

        $.ajax({
            url: "/templates/messageMaster.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#popUpHeader").show().html("Шаг 1. Выбор группы");
                $("#popUpContent").html(data);
                $("#step1").css({ "display": "block" });
                $("#step2").css({ "display": "none" });
                globObj["callback"] = "legacy.hidePopUp()";
                $.ajax({
                    url: legacy.api_folder + "groupsGetList.php",
                    dataType: "json",
                    data: {},
                    type: "POST",
                    success: function (data) {
                        let out = "";
                        for (let a in data) {
                            if (!data.hasOwnProperty(a)) continue;
                            out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["code"] + " - " + data[a]["name"] + "</option>";
                        }
                        $("#sendGroups").html(out);
                    },
                });

            },
        });
    },

    getGroupsListToSend: function () {
        $("#popUpHeader").show().html("Шаг 2. Тема и текст сообщения");
        $("#step1").css({ "display": "none" });
        $("#step2").css({ "display": "block" });
        alert($("#allGroups").prop("checked"));
    },

    getAnalytic: function () {
        $.ajax({
            url: "/templates/totalStat.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (html_content) {
                $contentHeader.show().html("Статистика с 01 июня 2016 года");
                let $spinner = $('<div class="d-flex justify-content-center">\n' +
                    '  <div class="spinner-border" role="status">\n' +
                    '    <span class="sr-only">Loading...</span>\n' +
                    '  </div>\n' +
                    '</div>');
                $pageContent.html($spinner);
                $.ajax({
                    url: legacy.api_folder + "getAnalytic.php",
                    dataType: "json",
                    data: {},
                    type: "GET",
                    success: function (data) {
                        $pageContent.html(html_content);
                        let out = "<br>";
                        out += "<table class=\"groupList\">";
                        $.each(data.departmentsDogovors, (key, value) => {
                            out += `<tr><td><b>Договора ${key}:</b></td><td>${value.count}</td></tr>`;
                        });
                        out += "<tr><td><b>Всего договоров:</b></td><td>" + data.departmentsDogovorsTotal + "</td></tr>";
                        out += "<tr><td><b>Активных слушателей:</b></td><td>" + data.activeStudents + "</td></tr>";
                        out += "</table>";

                        $("#totalStat").html(out);

                        out = "<br><b>В разрезе групп</b><br><br>";
                        out += "<table class=\"groupList\">";
                        for (let a in data["dksh"]["dogs"]) {
                            if (!data["dksh"]["dogs"].hasOwnProperty(a)) continue;
                            out += "<tr><td>" + data["dksh"]["dogs"][a]["course"]["shortName"] + "</td><td style=\"width: 50px;\" class=\"text-center\">" + data["dksh"]["dogs"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\"><b>Итого:</b></td><td class=\"text-center\"><b>" + data["dksh"]["count"] + "</b></td></tr>";
                        out += "</table>";
                        out += "<br><b>Рекламная эффективность</b><br><br>";
                        out += "<table class=\"groupList\"  style=\"width: 800px;\">";
                        for (let a in data["dksh"]["rek"]) {
                            if (!data["dksh"]["rek"].hasOwnProperty(a)) continue;
                            let percent = Math.floor(data["dksh"]["rek"][a]["info"] / data["dksh"]["adver"] * 100);
                            out += "<tr><td>" + data["dksh"]["rek"][a]["aID"]["name"] + "</td><td style=\"width: 300px;\"><div style=\"background-color:" + Utils.getRandomBackground("setTwo") + "; height:20px; width:" + (percent * 3) + "px;\"></div></td><td style=\"width: 50px;\" class=\"text-center\">" + data["dksh"]["rek"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\" colspan=2><b>Итого:</b></td><td class=\"text-center\"><b>" + data["dksh"]["adver"] + "</b></td></tr>";
                        out += "</table>";
                        $("#dkshStat").html(out);

                        out = "<br><b>В разрезе групп</b><br><br>";
                        out += "<table class=\"groupList\"  style=\"width: 800px;\">";
                        for (let a in data["mt"]["dogs"]) {
                            if (!data["mt"]["dogs"].hasOwnProperty(a)) continue;
                            out += "<tr><td>" + data["mt"]["dogs"][a]["course"]["shortName"] + "</td><td style=\"width: 50px;\" class=\"text-center\">" + data["mt"]["dogs"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\"><b>Итого:</b></td><td class=\"text-center\"><b>" + data["mt"]["count"] + "</b></td></tr>";
                        out += "</table>";
                        out += "<br><b>Рекламная эффективность</b><br><br>";
                        out += "<table class=\"groupList\"  style=\"width: 800px;\">";
                        for (let a in data["mt"]["rek"]) {
                            if (!data["mt"]["rek"].hasOwnProperty(a)) continue;
                            let percent = Math.floor(data["mt"]["rek"][a]["info"] / data["mt"]["adver"] * 100);
                            out += "<tr><td>" + data["mt"]["rek"][a]["aID"]["name"] + "</td><td style=\"width: 300px;\"><div style=\"background-color:" + Utils.getRandomBackground("setTwo") + "; height:20px; width:" + (percent * 3) + "px;\"></div></td><td style=\"width: 50px;\" class=\"text-center\">" + data["mt"]["rek"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\" colspan=2><b>Итого:</b></td><td class=\"text-center\"><b>" + data["mt"]["adver"] + "</b></td></tr>";
                        out += "</table>";

                        $("#mtStat").html(out);

                        out = "<br><b>В разрезе групп</b><br><br>";
                        out += "<table class=\"groupList\"  style=\"width: 800px;\">";
                        for (let a in data["ege"]["dogs"]) {
                            if (!data["ege"]["dogs"].hasOwnProperty(a)) continue;
                            out += "<tr><td>" + data["ege"]["dogs"][a]["course"]["shortName"] + "</td><td style=\"width: 50px;\" class=\"text-center\">" + data["ege"]["dogs"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\"><b>Итого:</b></td><td class=\"text-center\"><b>" + data["ege"]["count"] + "</b></td></tr>";
                        out += "</table>";
                        out += "<br><b>Рекламная эффективность</b><br><br>";
                        out += "<table class=\"groupList\"  style=\"width: 800px;\">";
                        for (let a in data["ege"]["rek"]) {
                            if (!data["ege"]["rek"].hasOwnProperty(a)) continue;
                            let percent = Math.floor(data["ege"]["rek"][a]["info"] / data["ege"]["adver"] * 100);
                            out += "<tr><td>" + data["ege"]["rek"][a]["aID"]["name"] + "</td><td style=\"width: 300px\"><div style=\"background-color:" + Utils.getRandomBackground("setTwo") + "; height:20px; width:" + (percent * 3) + "px;\"></div></td><td style=\"width: 50px; text-align: center\">" + data["ege"]["rek"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\" colspan=2><b>Итого:</b></td><td class=\"text-center\"><b>" + data["ege"]["adver"] + "</b></td></tr>";
                        out += "</table>";

                        $("#egeStat").html(out);

                        out = "<br><b>В разрезе групп</b><br><br>";
                        out += "<table class=\"groupList\" style=\"width: 800px;\">";
                        for (let a in data["vg"]["dogs"]) {
                            if (!data["vg"]["dogs"].hasOwnProperty(a)) continue;
                            out += "<tr><td>" + data["vg"]["dogs"][a]["course"]["shortName"] + " (" + data["vg"]["dogs"][a]["course"]["ID"] + ")</td><td style=\"width: 50px;\" class=\"text-center\">" + data["vg"]["dogs"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\"><b>Итого:</b></td><td class=\"text-center\"><b>" + data["vg"]["count"] + "</b></td></tr>";
                        out += "</table>";
                        out += "<br><b>Рекламная эффективность</b><br><br>";
                        out += "<table class=\"groupList\">";
                        for (let a in data["vg"]["rek"]) {
                            if (!data["vg"]["rek"].hasOwnProperty(a)) continue;
                            let percent = Math.floor(data["vg"]["rek"][a]["info"] / data["ege"]["adver"] * 100);
                            out += "<tr><td>" + data["vg"]["rek"][a]["aID"]["name"] + "</td><td style=\"width: 300px;\"><div style=\"background-color:" + Utils.getRandomBackground("setTwo") + "; height:20px; width:" + (percent * 3) + "px;\"></div></td><td style=\"width: 50px;\" class=\"text-center\">" + data["vg"]["rek"][a]["info"] + "</td></tr>";
                        }
                        out += "<tr><td class=\"text-end\" colspan=2><b>Итого:</b></td><td class=\"text-center\"><b>" + data["vg"]["adver"] + "</b></td></tr>";
                        out += "</table>";
                        $("#vgStat").html(out);
                    },
                });
            },
        });
    },

    getCourseLeaders: function () {
        $.ajax({
            url: legacy.api_folder + "getCourseLeaders.php",
            dataType: "json",
            data: {},
            type: "GET",
            success: function (data) {
                let out = "";
                for (let i in data) {
                    if (!data.hasOwnProperty(i)) continue;
                    let course = data[i],
                        place = 1,
                        result = course.result;
                    if (result.length === 0) {
                        continue;
                    }
                    out += "<div class=\"innerHeader\">" + course.info.shortName + "</div><br>";
                    out += "<table class=\"groupList\" >";
                    for (let j in result) {
                        if (!result.hasOwnProperty(j)) continue;
                        let currentResult = result[j];
                        out += `<tr><td style="width: 50px;" class="text-center"><b>${place}</b></td><td style="width: 100px;" class="text-center"><div class="minAvatar" style="background-image:url(${currentResult?.student?.avatar})"></div></td><td style="width: 250px;"><a href="/students/${currentResult.student.ID}/" class="studentList">${Utils.concatFio(currentResult.student.f, currentResult.student.i, currentResult.student.o)}</a></td><td class="text-center" style="width: 50px;">${currentResult.balls}</td><td>${currentResult.group.name}</td></tr>`;
                        place++;
                    }
                    out += "</table><br><br>";
                }

                $contentHeader.show().html("Лидеры Учебного Центра по курсам");
                $pageContent.html(out);
            },
        });
    },

    getGroupEffects: function () {
        $.ajax({
            url: legacy.api_folder + "getGroupsEffect.php",
            dataType: "json",
            type: "GET",
            data: {},
            success: function (data) {
                let out = "<table class=\"groupList\" ><tr><th>#</th><th>Группа</th><th>Код</th><th>Преподаватель</th><th>Эффективность</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    let color;
                    if (parseInt(data[a]["volume"]) >= 100) {
                        color = "#00dd00";
                    } else if (parseInt(data[a]["volume"]) >= 90 && parseInt(data[a]["volume"]) < 100) {
                        color = "#009900";
                    } else if (parseInt(data[a]["volume"]) >= 70 && parseInt(data[a]["volume"]) < 90) {
                        color = "#660000";
                    } else if (parseInt(data[a]["volume"]) >= 50 && parseInt(data[a]["volume"]) < 70) {
                        color = "#990000";
                    } else if (parseInt(data[a]["volume"]) < 50) {
                        color = "#cc0000";
                    } else {
                        color = "#ff0000";
                    }
                    out += "<tr><td>" + num + "</td><td><a href=\"/groups/" + data[a]["ID"] + "/\" >" + data[a]["name"] + "</a></td><td>" + data[a]["code"] + "</td><td>" + data[a]["teacher"] + "</td><td style=\"color:" + color + "\"><b>" + data[a]["volume"] + "%</b></td></tr>";
                    num++;
                }
                out += "</table>";
                $pageContent.html(out);
            },
        });
        $contentHeader.show().html("Эффективность групп");
    },

    makePOOrder: function () {
        $.ajax({
            url: "/templates/roomPOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $contentHeader.show().html("Заявки на программное обеспечение");
                $pageContent.html(data);
                legacy.getMyPOOrders();
            },
        });
    },

    makePOOrderAppl: function () {
        $.ajax({
            url: "/templates/addRoomPOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                });

                globObj["callback"] = "legacy.hidePopUp()";

                $("#popUpHeader").show().html("Новая заявка");
                $("#popUpContent").html(data);

                $.ajax({
                    url: legacy.api_folder + "roomsGetMyList.php",
                    type: "GET",
                    dataType: "json",
                    data: {},
                    success: function (data) {
                        let out = "";
                        for (let a in data) {
                            if (!data.hasOwnProperty(a)) continue;
                            out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["name"] + "</option>";
                        }
                        $("#poRoom").html(out);
                    },
                });

            },
        });
    },

    addPOOrder: function () {
        $.ajax({
            url: legacy.api_folder + "roomAddPOOrder.php",
            type: "POST",
            dataType: "json",
            data: { "poRoom": $("#poRoom").val(), "poList": $("#poList").val() },
            success: function () {
                legacy.globalCallback();
                legacy.makePOOrder();
            },
        });
    },

    getMyPOOrders: function () {
        $.ajax({
            url: legacy.api_folder + "roomGetPOOrders.php",
            type: "POST",
            dataType: "json",
            data: { "orders": "my" },
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>#</th><th>Дата</th><th>Кабинет</th><th>Описание заявки</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + num + "</td><td>" + data[a]["date"] + "</td><td>" + data[a]["room"] + "</td><td>" + data[a]["info"] + "</td></tr>";
                    num++;
                }
                out += "</table>";
                $("#oldPOOrders").html(out);
            },
        });
    },

    makeTOOrder: function () {
        $.ajax({
            url: "/templates/roomTOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $contentHeader.show().html("Заявки на техническое обслуживание");
                $pageContent.html(data);
                legacy.getMyTOOrders();
            },
        });
    },

    makeTOOrderAppl: function () {
        $.ajax({
            url: "/templates/addRoomTOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                });

                globObj["callback"] = "legacy.hidePopUp()";

                $("#popUpHeader").show().html("Новая заявка");
                $("#popUpContent").html(data);

                $.ajax({
                    url: legacy.api_folder + "roomsGetMyList.php",
                    type: "GET",
                    dataType: "json",
                    data: {},
                    success: function (data) {
                        let out = "";
                        for (let a in data) {
                            if (!data.hasOwnProperty(a)) continue;
                            out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["name"] + "</option>";
                        }
                        $("#toRoom").html(out);
                    },
                });

            },
        });
    },

    addTOOrder: function () {
        $.ajax({
            url: legacy.api_folder + "roomAddTOOrder.php",
            type: "GET",
            dataType: "json",
            data: { "poRoom": $("#toRoom").val(), "toList": $("#toList").val() },
            success: function () {
                legacy.globalCallback();
                legacy.makeTOOrder();
            },
        });
    },

    getMyTOOrders: function () {
        $.ajax({
            url: legacy.api_folder + "roomGetTOOrders.php",
            type: "GET",
            dataType: "json",
            data: { "orders": "my" },
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>#</th><th>Дата</th><th>Кабинет</th><th>Описание заявки</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + num + "</td><td>" + data[a]["date"] + "</td><td>" + data[a]["room"] + "</td><td>" + data[a]["info"] + "</td></tr>";
                    num++;
                }
                out += "</table>";
                $("#oldTOOrders").html(out);
            },
        });
    },

    makeMMOrder: function () {
        $.ajax({
            url: "/templates/roomMOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $contentHeader.show().html("Заявки на методический материал");
                $pageContent.html(data);
                legacy.getMyMOOrders();
            },
        });
    },

    makeMOOrderAppl: function () {
        $.ajax({
            url: "/templates/addRoomMOOrder.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                });

                globObj["callback"] = "legacy.hidePopUp()";

                $("#popUpHeader").show().html("Новая заявка");
                $("#popUpContent").html(data);

                $.ajax({
                    url: legacy.api_folder + "roomsGetMyGroups.php",
                    type: "GET",
                    dataType: "json",
                    data: {},
                    success: function (data) {
                        let out = "";
                        for (let a in data) {
                            if (!data.hasOwnProperty(a)) continue;
                            out += "<option value=\"" + data[a]["ID"] + "\">" + data[a]["name"] + " (" + data[a]["code"] + ")</option>";
                        }
                        $("#moGroups").html(out);
                    },
                });
            },
        });
    },

    addMOOrder: function () {
        $.ajax({
            url: legacy.api_folder + "roomAddMOOrder.php",
            type: "POST",
            dataType: "json",
            data: { "moGroup": $("#moGroups").val(), "toList": $("#moList").val() },
            success: function () {
                legacy.globalCallback();
                legacy.makeMMOrder();
            },
        });
    },

    getMyMOOrders: function () {
        $.ajax({
            url: legacy.api_folder + "roomGetMOOrders.php",
            type: "POST",
            dataType: "json",
            data: { "orders": "my" },
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>#</th><th>Дата</th><th>Группа</th><th>Описание заявки</th></tr>";
                let num = 1;
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + num + "</td><td>" + data[a]["date"] + "</td><td>" + data[a]["group"] + " (" + data[a]["code"] + ")</td><td>" + data[a]["info"] + "</td></tr>";
                    num++;
                }
                out += "</table>";
                $("#oldMOOrders").html(out);
            },
        });
    },

    getPersonalSet: function () {
        $.ajax({
            url: "/templates/teacherProfileDetail.html",
            dataType: "html",
            data: {},
            type: "GET",
            success: function (data) {
                $contentHeader.show().html("Редактирование профиля");
                $pageContent.html(data);
                $.ajax({
                    url: legacy.api_folder + "getTeacherProfileInfo.php",
                    data: {},
                    dataType: "json",
                    type: "GET",
                    success: function (data) {

                        let editableFields = {
                            "personalBday": "bdate",
                            "personalPhone": "phone",
                            "personalEmail": "email",
                        };
                        // сформируем поля для быстрого редактирования
                        $.each(editableFields, (key, value) => {
                            let $field = $("#" + key);
                            // проверим наличие значения поля в пришедших из аякса данных
                            if (data[value] === "") {
                                new EditableField($field, value, legacy.editStudentContactField);
                            } else {
                                $field.text(data[value]);
                            }
                        });


                        if (data["bdate"].length === 0) {
                            $("#personalBday").html("<a href=\"javascript:void(0);\" class=\"fillLink\" onclick=\"teacherFillFields('personalBday','bdate');\">заполнить</a>");
                        } else {
                            $("#personalBday").html(data["bdate"] + "<a href=\"javascript:void(0);\" onclick=\"teacherEditFields('personalBday','bdate','" + data["bdate"] + "');\" class=\"editInfo\"></a>");
                        }

                        if (data["phone"].length === 0) {
                            $("#personalPhone").html("<a href=\"javascript:void(0);\" class=\"fillLink\" onclick=\"teacherFillFields('personalPhone','phone');\">заполнить</a>");
                        } else {
                            $("#personalPhone").html(data["phone"] + "<a href=\"javascript:void(0);\" onclick=\"teacherEditFields('personalPhone','phone','" + data["phone"] + "');\" class=\"editInfo\"></a>");
                        }

                        if (data["email"].length === 0) {
                            $("#personalEmail").html("<a href=\"javascript:void(0);\" class=\"fillLink\" onclick=\"teacherFillFields('personalEmail','mail');\">заполнить</a>");
                        } else {
                            $("#personalEmail").html(data["email"] + "<a href=\"javascript:void(0);\" onclick=\"teacherEditFields('personalEmail','mail','" + data["mail"] + "');\" class=\"editInfo\"></a>");
                        }

                        $("#personalPhoto").html("<img src=\"" + data["avatar"] + "\" width=\"100%\" height=\"100%\" alt=\"фото\">");
                    },
                });
            },
        });
    },

    teacherFillFields: function (field, name) {
        let $name = $("#" + name);
        $("#" + field).html("<input type=\"text\" class=\"edInt\" id=\"" + name + "\" />");
        $name.focus();
        $name.on("keydown", function (e) {
            if (e.which === 13) {
                $.ajax({
                    url: legacy.api_folder + "teacherFieldEdit.php",
                    dataType: "json",
                    type: "POST",
                    data: { field: name, value: $("#" + name).val() },
                    success: function () {
                        legacy.getPersonalSet();
                    },
                });
            }
        });
    },

    /**
     * обработчик для изменения полей педагога
     * @param event
     */
    editTeacherContactField: function (event) {
        let $input = $(event.target),
            name = $input.attr("ID"),
            $editLink = $input.next();
        switch (event.which) {
            case 13:
                $.ajax({
                    url: legacy.api_folder + "teacherFieldEdit.php",
                    dataType: "json",
                    type: "POST",
                    data: { field: name, value: $input.val(), tID: globObj.tID },
                    success: function () {
                        legacy.getTeacherDetail(globObj.tID);
                    },
                });
                break;
            case 27:
                $input.remove();
                $editLink.show();
                break;
        }
        $(document).off("click.removeFieldEditInput");
    },

    teacherEditFields: function (field, name, value) {
        let $name = $("#" + name);
        $("#" + field).html("<input type=\"text\" class=\"edInt\" id=\"" + name + "\" value=\"" + value + "\" />");
        $name.focus();
        $name.on("keydown", function (e) {
            if (e.which === 13) {
                $.ajax({
                    url: legacy.api_folder + "teacherFieldEdit.php",
                    dataType: "json",
                    type: "POST",
                    data: { field: name, value: $("#" + name).val() },
                    success: function () {
                        legacy.getPersonalSet();
                    },
                });
            }
        });
    },

    diaryAddTest: function () {
        $.ajax({
            url: "/templates/diaryAddTest.html",
            dataType: "html",
            data: {},
            type: "POST",
            success: function (data) {
                $("#back").fadeIn(500, function () {
                    $("#popUp").fadeIn(500);
                    $("#popUpHeader").show().html("Добавление теста");
                    $("#popUpContent").html(data);
                    globObj["callback"] = "legacy.hidePopUp()";
                    $.ajax({
                        url: legacy.api_folder + "groupGetIncludedDogs.php",
                        dataType: "json",
                        type: "POST",
                        data: { gID: globObj["gID"] },
                        success: function (data) {
                            let out = "<table class=\"testInfo\">";
                            for (let a in data) {
                                if (!data.hasOwnProperty(a)) continue;
                                let current = data[a],
                                    student = current.student;
                                out += "<tr><td>" + Utils.concatFio(student.f, student.i, student.o) + "</td><td style=\"width: 50px; text-align: right;\"><input type=\"text\" style=\"width:50px !important\" id=\"st_" + current.dID + "\" ></td></tr>";
                            }
                            out += "</table>";
                            $("#stTesList").html(out);
                        },
                    });
                });
            },
        });
    },

    addDiaryTest: function () {
        let arValidateFieldsIds = ["testT", "testD", "testB"];
        let $testT = $("#testT"),
            $testD = $("#testD"),
            $testB = $("#testB");
        if (legacy.validateFields(arValidateFieldsIds)) {
            let sData = {
                testT: $testT.val(),
                testD: $testD.val(),
                testB: $testB.val(),
                testInfo: $("#testInfo").val(),
            };

            sData["visit"] = {};

            $(".editTable .testInfo input[type=text]").each(function (index, element) {
                sData["visit"][$(element).attr("id")] = $(element).val();
            });

            sData["gID"] = globObj["gID"];

            $.ajax({
                url: legacy.api_folder + "diaryAddTest.php",
                dataType: "json",
                type: "POST",
                data: sData,
                success: function () {
                    legacy.hidePopUp();
                    legacy.getDiaryRecord();
                },
            });
        }
    },

    getDiaryTests: function () {
        $.ajax({
            url: legacy.api_folder + "diaryGetTests.php",
            dataType: "json",
            type: "POST",
            data: { gID: globObj["gID"] },
            success: function (data) {
                let out = "<table class=\"groupList\"><tr><th>Дата</th><th>Тема теста</th><th>Посещаемость</th><th>Результативность</th><th>Описание</th><th>Действия</th></tr>";
                for (let a in data) {
                    if (!data.hasOwnProperty(a)) continue;
                    out += "<tr><td>" + data[a]["date"] + "</td><td>" + data[a]["theme"] + "</td><td>" + data[a]["visit"] + "</td><td>" + data[a]["result"] + "%</td><td>" + data[a]["description"] + "</td><td class=\"text-center\">" + data[a]["act"] + "</td></tr>";
                }
                out += "</table>";
                $("#testsList").html(out);
            },
        });
    },

    studentFillPaymentForm: function () {
        globObj["callback"] = "legacy.hidePopUp()";

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);

            $("#popUpHeader").show().text("Внесение оплаты по договору");

            $.ajax({
                url: "/templates/studentAddPayment.html",
                dataType: "html",
                data: {},
                type: "POST",
                success: function (data) {
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "studentsGetPaymentDogs.php",
                        dataType: "json",
                        data: { "sID": globObj["sID"] },
                        type: "POST",
                        success: function (data) {
                            let out = "";
                            for (let a in data["info"]) {
                                if (!data["info"].hasOwnProperty(a)) continue;
                                out += "<option value=\"" + data["info"][a][0] + "\">№ " + data["info"][a][1] + ", " + data["info"][a][2] + "</option>";
                            }
                            $("#paymentBase").html(out);
                        },
                    });
                },
            });
        });
    },

    addStudentPaymentQuery: function () {
        $.ajax({
            url: legacy.api_folder + "studentsWritePaymentDogs.php",
            dataType: "json",
            data: {
                "sID": globObj["sID"],
                "dID": $("#paymentBase").val(),
                "summ": $("#paymentSumm").val(),
                "type": $("#paymentType").val(),
            },
            type: "POST",
            success: function () {
                legacy.globalCallback();
                legacy.studentPaymentsRefresh(globObj["sID"]);
            },
        });
    },

    studentPaymentsRefresh: function (sID) {
        $.ajax({
            url: legacy.api_folder + "studentsGetPayments.php",
            dataType: "json",
            data: { "sID": sID },
            type: "POST",
            success: function (data) {
                legacy.getPaymentTable(data);
            },
        });
    },

    getPaymentTable: function (data) {
        let out = "<p>Нет оплат и начислений по слушателю!</p>",
            $container = $("#stPayment");
        if (data.pays.length === 0) {
            $container.html(out);
            return false;
        }

        out = "<table class=\"groupList\">" +
            "<tr>" +
            "<th>Дата</th>" +
            "<th>Договор</th>" +
            "<th>Оплата</th>" +
            "<th>Начисления</th>" +
            "<th>Ответственный</th>" +
            "</tr>";

        for (let dog in data["pays"]) {
            if (!data["pays"].hasOwnProperty(dog)) continue;
            if (dog === "totals") {
                continue;
            }
            let flowList = data["pays"][dog]["flow"];
            for (let key in flowList) {
                if (!flowList.hasOwnProperty(key)) continue;
                out += "<tr><td>" + flowList[key]["ts"] + "</td>";
                out += "<td>" + flowList[key]["dID"] + "</td>";
                if (flowList[key]["type_code"] === "deb") {
                    out += "<td class=\"text-end\">" + flowList[key]["deb"] + "</td><td>&nbsp;</td>";
                } else {
                    out += "<td>&nbsp;</td><td class=\"text-end\">" + flowList[key]["cred"] + "</td>";
                }
                out += "<td>" + flowList[key]["user"] + "</td></tr>";
            }
        }

        let totals = data["pays"]["totals"],
            totalDiffInt = +totals["diffInteger"],
            totalDiffStr = Utils.number_format(totalDiffInt, 0, ".", " "),
            totalDeb = Utils.number_format(totals["debit_total"], 0, ".", " "),
            totalCred = Utils.number_format(totals["credit_total"], 0, ".", " "),
            totalClass,
            totalString;

        switch (true) {
            case (totalDiffInt > 0):
                totalClass = "red";
                totalString = "Задолженность слушателя: ";
                break;
            case (totalDiffInt < 0):
                totalClass = "success";
                totalString = "Переплата: ";
                break;
            default:
                totalDiffStr = totalString = totalClass = "";
                break;
        }

        out += "<tr>" +
            "<td class=\"strong\">Итог:</td>" +
            "<td></td>" +
            "<td class=\"text-end fw-bold\">" + totalDeb + "</td>" +
            "<td class=\"text-end fw-bold\">" + totalCred + "</td>" +
            "<td>&nbsp;</td>" +
            "</tr>" +
            "<tr>" +
            "<td class=\"text-end fw-bold " + totalClass + "\" colspan=\"5\">" + totalString + totalDiffStr + "</td>" +
            "</tr>";

        out += "</table>";
        $container.html(out);
    },

    searchByPhone: function () {
        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);
            $("#popUpHeader").show().text("Поиск по номеру телефона родителей");
            $.ajax({
                url: "/templates/studentSearchByPhone.html",
                dataType: "html",
                data: {},
                success: function (data) {
                    let $content = $("#popUpContent");
                    $content.html(data);
                    $content.find("#doSearch").on("click", legacy.searchByPhoneQuery);
                    $content.find("#cancelPopup").on("click", legacy.hidePopUp);
                },
            });
        });
    },

    searchByPhoneQuery: function () {
        let searchValue = $("#contactPhone").val();
        $.ajax({
            url: legacy.api_folder + "studentSearchByPhone.php",
            dataType: "json",
            data: { "phone": searchValue },
            type: "POST",
            success: function (data) {
                data.outerSearch = {
                    name: "Поиск по номеру телефона родителей.",
                    value: searchValue,
                };
                legacy.hidePopUp();
                legacy.renderStudentList(data);
            },
        });
    },

    popupGroupSelect: function (cID, dID) {
        globObj["callback"] = "legacy.hidePopUp()";

        $("#back").fadeIn(500, function () {
            $("#popUp").fadeIn(500);

            $("#popUpHeader").show().text("Подбор группы для договора");

            $.ajax({
                url: "/templates/selectGroupsByDog.html",
                dataType: "html",
                data: {},
                type: "GET",
                success: function (data) {
                    $("#popUpContent").html(data);
                    $.ajax({
                        url: legacy.api_folder + "selectGroupsByDog.php",
                        dataType: "json",
                        data: { "cID": cID },
                        type: "POST",
                        success: function (data) {
                            let out = "";
                            for (let a in data) {
                                if (!data.hasOwnProperty(a)) continue;
                                let group = data[a];
                                out += "<h4><u>" + group["name"] + "</u></h4><br><small><b>Расписание:</b> " + group["shedule"] + "<b>Преподаватель:</b> " + group["teacher"]["FIO"] + "<br><b>Наполняемость:</b> " + group["count"][0] + " / " + group["max"] + "</small><br><a href=\"javascript:void(0);\" onclick=\"return false; chooseGroup(" + dID + ", " + group["ID"] + ")\" class=\"fillLink\" style=\"font-size:14px;\">Включить в группу</a><br><br> ";
                            }
                            if (out.length === 0) {
                                out += "<div class='text-danger'>Нет групп соответствующих условиям!</div>";
                            }
                            $("#dogGroupsList").html(out);
                        },
                    });
                },
            });
        });
    },

    chooseGroup: function (dID, gID) {
        $.ajax({
            url: legacy.api_folder + "dogovorAction.php",
            type: "POST",
            dataType: "json",
            data: { act: "inc", dID: dID, gID: gID },
            success: function () {
                legacy.hidePopUp();
                window.location.href = `/students/${globObj["sID"]}/`;
            },
        });
    },

    IdMissingException: function () {
        this.message = "Не установлен ID элемента!";
        this.toString = function () {
            return this.message;
        };
    },

    setFilterTeacherId: function () {
        console.log('qwe');

        let filterTeacher = $('#filter-teacher'),
            filterTeacherInput = filterTeacher.children('input'),
            filterTeacherList = filterTeacher.children('datalist');

        if (filterTeacher.length && filterTeacherInput.length && filterTeacherList.length) {
            console.log(filterTeacherList.children('option:selected').data('value'));
        }
    },

};

